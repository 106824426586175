<div class="left">
  @if (image) {
    <img [src]="image"
      alt="">
  }

  <div class="title-description">
    @if (title) {
      <div class="font-l-semibold title">
        {{ title }}
      </div>
    } @else {
      <h2 class="font-l-semibold title">
        <ng-content select="[title]"></ng-content>
      </h2>
    }

    @if (description) {
      <div class="font-multiline-normal-regular description"
        [innerHTML]="description">
      </div>
    }

    <ng-content select="[left-content]"></ng-content>
  </div>
</div>

<div class="right">
  <ng-content></ng-content>
</div>
