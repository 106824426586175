import { EmbeddingService, EmbedMessageNavigate } from '@accredible-frontend-v2/embedding';
import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { DashboardIssuerService } from '@dashboard/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const directorySettingsGuard: CanActivateFn = (): Observable<boolean> => {
  const issuer = inject(DashboardIssuerService).loadIssuer();
  const embedding = inject(EmbeddingService);

  return issuer.pipe(
    map((issuer) => {
      if (
        issuer.department.pricing.features.spotlight_directory ||
        issuer.department.pricing.features.private_spotlight_directory
      ) {
        return true;
      }

      // TODO V1(Fred): Change to router navigation when moving away from V1
      //   return router.createUrlTree(['/']);
      embedding.postMessage<EmbedMessageNavigate>({
        type: 'navigate',
        payload: {
          route: 'issuer.dashboard.home',
          isNavigatingFromV2ToV1: true,
        },
      });

      return false;
    }),
  );
};
