import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HintComponent } from './hint.component';

@NgModule({
  imports: [CommonModule, AccredibleIconComponentModule],
  exports: [HintComponent],
  declarations: [HintComponent],
})
export class HintComponentModule {}
