import { AccredibleTileGalleryModule } from '@accredible-frontend-v2/components/tile-gallery';
import { WalletCredential } from '@accredible-frontend-v2/models';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CredentialSelectionTileComponent } from '../credential-selection-tile/credential-selection-tile.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AccredibleTileGalleryModule,
    InfiniteScrollModule,
    CredentialSelectionTileComponent,
  ],
  selector: 'accredible-credential-selection-list',
  templateUrl: './credential-selection-list.component.html',
  styleUrls: ['./credential-selection-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialSelectionListComponent {
  credentials = input<WalletCredential[]>([]);
  fetchMore = output<void>();
  credentialSelected = output<{ id: number; selected: boolean; credential: WalletCredential }>();

  onSelectionChange(id: number, selected: boolean, credential: WalletCredential): void {
    this.credentialSelected.emit({ id, selected, credential });
  }
}
