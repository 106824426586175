import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-form-section-component',
  templateUrl: 'form-section.component.html',
  styleUrls: ['form-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent {
  @Input()
  title: string;
  @Input()
  image: string;
  @Input()
  description: string;
}
