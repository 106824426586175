<div *transloco="let t; read: 'viewers-sidenav'"
  class="sidenav">
  <div class="header">
    <div class="title">
      <div class="top-row">
        <div class="left">
          @if (onInviteForm()) {
            <button class="btn-back"
              (click)="onInviteForm.set(false)">
              <accredible-icon [icon]="'chevron-left'"/>
            </button>

            <div class="left-side-actions">
              <span class="font-s-regular">
                {{ t('back') }}
              </span>

              <div class="font-normal-semibold">
                {{ t('invite_viewers') }}
              </div>
            </div>
          } @else {
            <div class="font-normal-semibold">
              {{ t('invited_viewers') }}
            </div>
          }
        </div>

        <div class="action-buttons">
          @if (!onInviteForm()) {
            <button accredible-button
              (click)="onInviteForm.set(true)">
              {{ t('invite_viewers') }}
            </button>
          }

          <button accredible-button-flat
            class="btn-close"
            size="small"
            iconSize="14px"
            icon="close"
            (click)="close()">
          </button>
        </div>
      </div>

      @if (!onInviteForm()) {
        <div class="subtitle">
          <span class="font-s-regular">
            {{ t('invited_viewers_text') }}
          </span>
        </div>
      }
    </div>
  </div>

  @if (onInviteForm()) {
    <db-invite-viewers [spotlightDirectoryId]="data.id"
      (inviteSent)="onInviteForm.set(false)"/>
  } @else {
    <div class="invited-viewers-list">
      <db-list-viewers [spotlightDirectoryId]="data.id"/>
    </div>
  }
</div>
