import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const emailDuplicateValidator = (emails: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toLowerCase();

    if (value) {
      return emails.includes(value) ? { duplicateEmail: true } : null;
    }

    return null;
  };
};
