import { DashboardFeatures } from './db-features.model';
import { DepartmentSecuritySettings } from './db-issuer.model';

interface DashboardPricing {
  id: number;
  corporation_id: number;
  features: DashboardFeatures;
  plan: string;
  plan_size: number;
  premium_whitelabel: boolean;
}

interface DashboardSocialMedia {
  twitter: DashboardTwitter;
  facebook: string;
  linkedin: string;
  seo: DashboardSEO;
}

interface DashboardSEO {
  title: string;
  description: string;
}

interface DashboardTwitter {
  id: string;
  message: string;
}

export interface DashboardDepartment {
  id: number;
  name: string;
  contact_first_name: string;
  contact_last_name: string;
  username: string;
  email: string;
  url: string;
  description: string;
  linkedin_school_id: string;
  linkedin_company_id: string;
  sandbox_mode: boolean;
  allow_supplemental_references: boolean;
  allow_supplemental_evidence: boolean;
  created_at: number;
  language: string;
  onboard_status: string;
  phone_number: string;
  email_verification_done: boolean;
  recipient_functions: string;
  pricing: DashboardPricing;
  name_changes_auto_approve: string;
  source: string;
  logo: string;
  whitelisted_domain: string;
  custom_email_name: string;
  social_media: DashboardSocialMedia;
  certificate_expiry_message: string;
  found_accredible: string;
  block_credential: boolean;
  generate_private_credential: boolean;
  support_contact_email: string;
  signup_intention: string;
  job_role: string;
  number_of_recipients: string;
  industry: string;
  cpc: number;
  page_banner: string;
  credential_visibility: string;
  customer: boolean;
  customer_id: string;
  image_url: string;
  certificate_left: number;
  whitelabel_logo: string;
  whitelabel_domain: string;
  whitelabel_active: string;
  reseller_child: boolean;
  show_directory: boolean;
  has_updated_email_appearance_settings: boolean;
  security_setting: DepartmentSecuritySettings;
  active_email_campaigns: number;
  essential_campaigns_fully_enabled: boolean;
  active_campaigns_limit: number;
}
