import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { addErrors, removeErrors } from './validator.helper';

export const duplicateValuesValidator = (controls: string[]) => {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const values = controls
      .map((control) => ({ control, value: formGroup.get(control).value }))
      .filter(({ value }) => value !== null && value !== undefined);

    const valueCounts = values.reduce((counts, { value }) => {
      counts[value] = (counts[value] || 0) + 1;
      return counts;
    }, <any>{});

    let hasError = false;
    for (const { control, value } of values) {
      if (valueCounts[value] > 1) {
        addErrors(formGroup.get(control), { duplicateValues: true }, true);
        hasError = true;
      } else {
        removeErrors(formGroup.get(control), ['duplicateValues']);
      }
    }

    return hasError ? { duplicateValues: true } : null;
  };
};
