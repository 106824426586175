import {
  DirectoryDepartment,
  DirectorySettings,
  EligibilitySource,
} from '@accredible-frontend-v2/models';
import { inject, Injectable } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError, exhaustMap } from 'rxjs/operators';
import { DirectorySettingsApiService } from './directory-settings.service';

export interface DirectorySettingsState {
  directorySettings: DirectorySettings;
  groups: EligibilitySource[];
  departments: DirectoryDepartment[];

  action: DirectorySettingsStateAction;
  payload: any;
  error: any;
}

export enum DirectorySettingsStateAction {
  NO_ACTION,

  DIRECTORY_SETTINGS_LOADED,
  DIRECTORY_SETTINGS_UPDATED,

  HAS_ERROR,
}

const initialStateHandling: Partial<DirectorySettingsState> = {
  action: DirectorySettingsStateAction.NO_ACTION,
  payload: null,
  error: null,
};

export const initialState: DirectorySettingsState = {
  directorySettings: null,
  groups: null,
  departments: null,

  ...(<DirectorySettingsState>initialStateHandling),
};

@Injectable()
export class DirectorySettingsStore extends ComponentStore<DirectorySettingsState> {
  private readonly _directorySettingsApi = inject(DirectorySettingsApiService);

  constructor() {
    super(initialState);
  }

  readonly setInitialStateHandling = this.updater((state: DirectorySettingsState) => {
    return {
      ...state,
      ...(<DirectorySettingsState>initialStateHandling),
    };
  });

  readonly loadDirectorySettings = this.effect((action$: Observable<{ directoryId: number }>) => {
    return action$.pipe(
      exhaustMap((action) => {
        this.setInitialStateHandling();

        const settings$ = this._directorySettingsApi
          .loadDirectorySettings(action.directoryId)
          .pipe(catchError((error) => this._handleError(error)));
        const groups$ = this._directorySettingsApi.loadDirectoryGroups().pipe();
        const departments$ = this._directorySettingsApi
          .loadDirectoryDepartments()
          .pipe(catchError((error) => this._handleError(error)));

        return forkJoin([settings$, groups$, departments$]).pipe(
          tapResponse(
            ([settings, groups, departments]) => {
              this.setState((state) => ({
                ...state,
                directorySettings: { ...settings },
                groups: [...groups],
                departments: [...departments],
                ...(<DirectorySettingsState>initialStateHandling),
                action: DirectorySettingsStateAction.DIRECTORY_SETTINGS_LOADED,
              }));
            },
            (error) => this._handleError(error),
          ),
        );
      }),
    );
  });

  readonly editDirectorySettings = this.effect(
    (action$: Observable<{ directoryId: number; directorySettings: DirectorySettings }>) => {
      return action$.pipe(
        exhaustMap((action) => {
          this.setInitialStateHandling();

          return this._directorySettingsApi
            .editDirectorySettings(action.directoryId, action.directorySettings)
            .pipe(
              tapResponse(
                (directorySettings) => {
                  this.setState((state) => ({
                    ...state,
                    directorySettings: { ...directorySettings },
                    ...(<DirectorySettingsState>initialStateHandling),
                    action: DirectorySettingsStateAction.DIRECTORY_SETTINGS_UPDATED,
                  }));
                },
                (error) => this._handleError(error),
              ),
            );
        }),
      );
    },
  );

  readonly directorySettings$ = this.select((state) => state.directorySettings);
  readonly evaluatorProfilesCount$ = this.select(
    (state) => state.directorySettings.spotlight_directory_evaluator_profiles_count,
  );

  private _handleError(
    error: any,
    action: DirectorySettingsStateAction = DirectorySettingsStateAction.HAS_ERROR,
  ): Observable<never> {
    this.setState((state) => ({
      ...state,
      action,
    }));

    return throwError(error);
  }
}
