import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { TranslocoModule } from '@jsverse/transloco';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';

@Component({
  standalone: true,
  selector: 'db-delete-viewer-confirmation',
  templateUrl: './delete-viewer-confirmation.component.html',
  styleUrls: ['./delete-viewer-confirmation.component.scss'],
  imports: [
    CommonModule,
    AccredibleButtonComponentModule,
    TranslocoModule,
    AccredibleIconComponentModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteViewerConfirmationComponent {
  deletionCount = input<number>(1);
  confirmDeletion = output<void>();
  cancelDeletion = output<void>();

  cancel(event: Event): void {
    // stopPropagation needed to prevent row selection
    event.stopPropagation();
    this.cancelDeletion.emit();
  }

  delete(event: Event): void {
    // stopPropagation needed to prevent row selection
    event.stopPropagation();
    this.confirmDeletion.emit();
  }
}
