import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { TextFieldModule } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccredibleTextInputComponent } from './text-input.component';

@NgModule({
  declarations: [AccredibleTextInputComponent],
  imports: [CommonModule, ReactiveFormsModule, AccredibleIconComponentModule, TextFieldModule],
  exports: [AccredibleTextInputComponent],
})
export class AccredibleTextInputModule {}
