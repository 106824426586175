import { AccredibleCredentialAppearanceComponentModule } from '@accredible-frontend-v2/components/credential-appearance';
import { WalletCredential } from '@accredible-frontend-v2/models';
import { AccredibleChipComponentModule } from '@accredible-frontend-v2/new-components/chip';
import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { LazyLoadImageModule } from 'ng-lazyload-image';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AccredibleCredentialAppearanceComponentModule,
    AccredibleLocaleDatePipeModule,
    TranslocoModule,
    LazyLoadImageModule,
    AccredibleChipComponentModule,
  ],
  selector: 'accredible-credential-tile-content',
  templateUrl: 'credential-tile-content.component.html',
  styleUrls: ['credential-tile-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialTileContentComponent {
  private readonly _language = inject(AccredibleLanguageService);

  credential = input<WalletCredential>();

  languageCode$ = this._language.languageCode$;
}
