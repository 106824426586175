import { ActiveFeatureFlags } from '@accredible-frontend-v2/models';
import {
  AccredibleDividerComponent,
  FormSectionComponentModule,
} from '@accredible-frontend-v2/new-components/ui-common';
import { ChangeDetectionStrategy, Component, inject, OnInit, output, signal } from '@angular/core';
import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { DashboardIssuerService } from '@dashboard/domain';
import { TranslocoDirective } from '@jsverse/transloco';
import { tap } from 'rxjs';
import { DirectoryVisibilitySettingsComponent } from '../directory-visibility-settings/directory-visibility-settings.component';
import { ProfileVisibilitySettingsComponent } from '../profile-visibility-settings/profile-visibility-settings.component';

export interface DirectoryVisibilityForm {
  visibility: FormGroup<{
    private: FormControl<boolean>;
    profileVisibility: FormGroup<{
      usersVisibleByDefault: FormControl<boolean>;
      credentialVisibility: FormControl<boolean>;
      includeExpiredCredentials: FormControl<boolean>;
      additionalLinks: FormControl<boolean>;
    }>;
  }>;
}

@Component({
  standalone: true,
  imports: [
    FormSectionComponentModule,
    TranslocoDirective,
    DirectoryVisibilitySettingsComponent,
    ProfileVisibilitySettingsComponent,
    AccredibleDividerComponent,
  ],
  selector: 'db-visibility-settings',
  templateUrl: './visibility-settings.component.html',
  styleUrls: ['./visibility-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisibilitySettingsComponent implements OnInit {
  private readonly _controlContainer = inject(ControlContainer);
  private readonly _issuerService = inject(DashboardIssuerService);

  saveChanges = output();

  formGroup: FormGroup<DirectoryVisibilityForm>;

  privateSpotlightFF = signal<boolean>(false);
  spotlightProfileRedesignFF = signal<boolean>(false);

  ngOnInit(): void {
    this.formGroup = this._controlContainer.control as FormGroup;

    this._issuerService
      .loadFeatureFlags()
      .pipe(
        tap((flags) => {
          this.privateSpotlightFF.set(flags[ActiveFeatureFlags.PRIVATE_SPOTLIGHT]);
          this.spotlightProfileRedesignFF.set(flags[ActiveFeatureFlags.SPOTLIGHT_PROFILE_REDESIGN]);
        }),
      )
      .subscribe();
  }
}
