// TODO (MattG): We now have two validators (urlValidator and strictUrlValidator) that do very similar things, we should look into combining them into one if possible
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * This strict url validator differs from the urlValidator in that it enforces http:// or https:// at the start of the url
 */
export const strictUrlValidator = (enforceHttps?: boolean): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    const requireHttps = enforceHttps ? /https/ : /http(s?)/;

    // TODO: Allow spaces at the end, but implement trim on save in forms using this validator
    if (value) {
      // Source: variation of https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url - Daveo's Answer
      const webUrlRegex = new RegExp(
        '^(' +
          requireHttps.source +
          '://)(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)',
      );

      if (!webUrlRegex.test(value)) {
        return { strictUrl: true };
      }
    }

    return null;
  };
};
