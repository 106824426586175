import { ComponentType, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccredibleSidenavConfig } from './models/sidenav.model';
import { SidenavContainer } from './sidenav-container';
import { SidenavRef } from './sidenav-ref';

export const SIDENAV_DATA = new InjectionToken<unknown>('SIDENAV_DATA');

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AccredibleSidenavService {
  constructor(private readonly _overlay: Overlay) {}

  open<T>(
    component: ComponentType<T>,
    sidenavConfig?: AccredibleSidenavConfig,
    data?: unknown,
  ): SidenavRef {
    const overlayConfiguration = this._getOverlayConfig(sidenavConfig);
    const overlayRef = this._createSidenavOverlay(overlayConfiguration);
    const sidenavContainer = attachSidenavContainer(overlayRef);
    const sidenavRef = new SidenavRef(overlayRef, sidenavContainer);
    attachComponentToContainer(component, sidenavContainer, overlayRef, sidenavRef, data);
    this._handleSidenavCustomConfig(sidenavRef, { ...overlayConfiguration, ...sidenavConfig });

    return sidenavRef;
  }

  private _createSidenavOverlay(config: OverlayConfig): OverlayRef {
    const overlayConfig = this._getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  private _getOverlayConfig(config: OverlayConfig): OverlayConfig {
    const defaultOverlayConfig = {
      hasBackdrop: true,
      width: '500px',
      height: '100%',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position().global().right(),
    };

    return new OverlayConfig({
      ...defaultOverlayConfig,
      ...config,
    });
  }

  private _handleSidenavCustomConfig(
    sideNavRef: SidenavRef,
    overlayConfig: AccredibleSidenavConfig,
  ): void {
    if (overlayConfig.closeOnBackdropClick) {
      sideNavRef.overlayRef
        .backdropClick()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          sideNavRef.close();
        });
    }
  }
}

const attachSidenavContainer = (overlayRef: OverlayRef): SidenavContainer => {
  const containerPortal = new ComponentPortal(SidenavContainer);
  const containerRef = overlayRef.attach<SidenavContainer>(containerPortal);
  return containerRef.instance;
};

const attachComponentToContainer = <T>(
  component: ComponentType<T>,
  sidenavContainer: SidenavContainer,
  overlayRef: OverlayRef,
  sidenavRef: SidenavRef,
  sidenavData: unknown,
): T => {
  const injector = createComponentInjectables(sidenavData, sidenavRef);
  const componentPortal = new ComponentPortal(component, null, injector);
  const sidenavContainerRef = sidenavContainer.attachComponentPortal(componentPortal);
  return sidenavContainerRef.instance;
};

// Adds providers allowing component inside the overlay to have access to them via DI
const createComponentInjectables = (sidenavData: unknown, overlayRef: SidenavRef): Injector => {
  return Injector.create({
    providers: [
      {
        provide: SidenavRef,
        useValue: overlayRef,
      },
      {
        provide: SIDENAV_DATA,
        useValue: sidenavData,
      },
    ],
  });
};
