import { AccrediblePageMeta } from '@accredible-frontend-v2/models';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

interface ViewingFromTo {
  start: number;
  end: number;
  totalCount: number;
  pageName: string;
}

interface PageFromTo {
  currentPage: number;
  lastPage: number;
}

@Component({
  selector: 'accredible-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrediblePaginationComponent {
  @Input()
  useCustomViewingFromTo = false;
  @Input()
  useCustomPageFromTo = false;

  @Output()
  pageChange = new EventEmitter<number>();

  viewingFromTo: ViewingFromTo;
  pageFromTo: PageFromTo;

  private _pageMeta: AccrediblePageMeta;
  private _pageName = '';

  get pageMeta(): AccrediblePageMeta {
    return this._pageMeta;
  }

  @Input()
  set pageMeta(pageMeta: AccrediblePageMeta) {
    this._pageMeta = pageMeta;
    this.viewingFromTo = {
      start: (pageMeta.current_page - 1) * pageMeta.page_size + 1,
      end:
        pageMeta.current_page === pageMeta.total_pages
          ? pageMeta.total_count
          : pageMeta.current_page * pageMeta.page_size,
      totalCount: pageMeta.total_count,
      pageName: this.pageName,
    };
    this.pageFromTo = {
      currentPage: pageMeta.current_page,
      lastPage: pageMeta.total_pages,
    };
  }

  get pageName(): string {
    return this._pageName;
  }

  @Input()
  set pageName(pageName: string) {
    this._pageName = pageName;

    this.viewingFromTo = {
      ...this.viewingFromTo,
      pageName,
    };
  }
}
