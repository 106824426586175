import { DirectorySettings } from '@accredible-frontend-v2/models';
import { AccredibleDialogService } from '@accredible-frontend-v2/new-components/dialog';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import {
  AccredibleRadioGroupOption,
  AccredibleRadioInputComponentModule,
} from '@accredible-frontend-v2/new-components/radio-input';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { AccredibleSidenavService } from '@accredible-frontend-v2/services/sidenav';
import { Overlay } from '@angular/cdk/overlay';
import { CommonModule, DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
  signal,
  Type,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, FormGroup } from '@angular/forms';
import { DashboardFeatures, DashboardIssuerService } from '@dashboard/domain';
import { TranslocoDirective } from '@jsverse/transloco';
import { LetDirective } from '@ngrx/component';
import { filter, take, tap } from 'rxjs';
import { DirectorySettingsStore } from '../../store/directory-settings.store';
import { TeamMembersSidenavComponent } from '../team-members-sidenav/team-members-sidenav.component';
import { ViewersSidenavComponent } from '../viewers-sidenav/viewers-sidenav.component';
import { DirectoryVisibilityForm } from '../visibility-settings/visibility-settings.component';

enum TranslationKey {
  PUBLIC_TITLE = 'directory-visibility-settings.public_directory.title',
  PUBLIC_DESCRIPTION = 'directory-visibility-settings.public_directory.description',
  PRIVATE_TITLE = 'directory-visibility-settings.private_directory.title',
  PRIVATE_DESCRIPTION = 'directory-visibility-settings.private_directory.description',
  TO_PRIVATE_DIALOG_TITLE = 'directory-visibility-settings.to_private_dialog.title',
  TO_PRIVATE_DIALOG_TEXT = 'directory-visibility-settings.to_private_dialog.text',
  TO_PRIVATE_DIALOG_BUTTON = 'directory-visibility-settings.to_private_dialog.button',
  TO_PUBLIC_DIALOG_TITLE = 'directory-visibility-settings.to_public_dialog.title',
  TO_PUBLIC_DIALOG_TEXT = 'directory-visibility-settings.to_public_dialog.text',
  TO_PUBLIC_DIALOG_BUTTON = 'directory-visibility-settings.to_public_dialog.button',
  BACK = 'directory-visibility-settings.back',
}

export enum EvaluatorType {
  VIEWER = 'viewer',
  TEAM_MEMBER = 'team_member',
}

const SPOTLIGHT_HELP_LINK =
  'https://help.accredible.com/s/article/spotlight-directory-issuer-settings';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LetDirective,
    TranslocoDirective,
    AccredibleRadioInputComponentModule,
    AccredibleIconComponentModule,
    AccredibleTooltipModule,
    AccredibleLinkComponentModule,
  ],
  selector: 'db-directory-visibility-settings',
  templateUrl: './directory-visibility-settings.component.html',
  styleUrls: ['./directory-visibility-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirectoryVisibilitySettingsComponent implements OnInit {
  private readonly _directorySettingsStore = inject(DirectorySettingsStore);
  private readonly _issuerService = inject(DashboardIssuerService);
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _controlContainer = inject(ControlContainer);
  private readonly _dialog = inject(AccredibleDialogService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _document = inject(DOCUMENT);
  private readonly _sideNav = inject(AccredibleSidenavService);
  private readonly _overlay = inject(Overlay);

  saveChanges = output();

  formGroup: FormGroup<DirectoryVisibilityForm>;
  options = signal<AccredibleRadioGroupOption[]>([]);
  readonly evaluatorType = EvaluatorType;
  readonly radioInputDataCy = 'radio-input-directory-visibility';
  readonly evaluatorProfilesCount$ = this._directorySettingsStore.evaluatorProfilesCount$;

  ngOnInit(): void {
    this.formGroup = this._controlContainer.control as FormGroup;

    this._issuerService
      .loadIssuer()
      .pipe(tap((issuer) => this._setupOptions(issuer.department.pricing.features)))
      .subscribe();

    this._handleOptionChanges();
  }

  onInviteViewersOrTeamMembers(type: EvaluatorType): void {
    this._directorySettingsStore.directorySettings$.pipe(take(1)).subscribe((directorySettings) => {
      if (!directorySettings.private) {
        this._openConfirmToPrivateDialog();
        return;
      }

      if (type === EvaluatorType.VIEWER) {
        this._openSidenavComponent(ViewersSidenavComponent, directorySettings);
      } else {
        this._openSidenavComponent(TeamMembersSidenavComponent, directorySettings);
      }
    });
  }

  private _openSidenavComponent<C>(component: Type<C>, directorySettings: DirectorySettings): void {
    this._sideNav.open(
      component,
      {
        hasBackdrop: true,
        positionStrategy: this._overlay.position().global().right(),
        width: 600,
        height: '100%',
        closeOnBackdropClick: true,
      },
      {
        id: directorySettings.id,
      },
    );
  }
  private _setupOptions(features: DashboardFeatures): void {
    this.options.set([
      {
        value: false,
        label: this._language.selectTranslate(TranslationKey.PUBLIC_TITLE),
        extendedDescription: this._language.selectTranslate(TranslationKey.PUBLIC_DESCRIPTION),
        disabled: !features.spotlight_directory,
        premiumFeature: !features.spotlight_directory ? { tooltipLink: SPOTLIGHT_HELP_LINK } : null,
      },
      {
        value: true,
        label: this._language.selectTranslate(TranslationKey.PRIVATE_TITLE),
        extendedDescription: this._language.selectTranslate(TranslationKey.PRIVATE_DESCRIPTION),
        disabled: !features.private_spotlight_directory,
        premiumFeature: !features.private_spotlight_directory
          ? { tooltipLink: SPOTLIGHT_HELP_LINK }
          : null,
      },
    ]);
  }

  private _handleOptionChanges(): void {
    this.formGroup.controls.visibility.controls.private.valueChanges
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        filter((value) => !value),
      )
      .subscribe(() => this._openConfirmToPublicDialog());
  }

  private _openConfirmToPrivateDialog(): void {
    this._dialog
      .confirm({
        title: this._language.translate(TranslationKey.TO_PRIVATE_DIALOG_TITLE),
        text: this._language.translate(TranslationKey.TO_PRIVATE_DIALOG_TEXT),
        image: '/assets/images/hints-and-guidance//upload-done.svg',
        btnText: this._language.translate(TranslationKey.TO_PRIVATE_DIALOG_BUTTON),
        btnColor: 'primary',
        cancelBtnText: this._language.translate(TranslationKey.BACK),
      })
      .closed.pipe(filter((confirmed) => !!confirmed))
      .subscribe(() => this.saveChanges.emit());
  }

  private _openConfirmToPublicDialog(): void {
    this._dialog
      .confirm({
        title: this._language.translate(TranslationKey.TO_PUBLIC_DIALOG_TITLE),
        text: this._language.translate(TranslationKey.TO_PUBLIC_DIALOG_TEXT),
        image: '/assets/images/hints-and-guidance/warning-red.svg',
        btnText: this._language.translate(TranslationKey.TO_PUBLIC_DIALOG_BUTTON),
        btnColor: 'natural',
        btnType: 'outline',
        cancelBtnText: this._language.translate(TranslationKey.BACK),
        cancelBtnColor: 'primary',
        cancelBtnType: 'outline',
      })
      .closed.subscribe((confirmed) => {
        if (!confirmed) {
          // If the user doesn't confirm changing to public, move selected radio button back to private
          this.formGroup.controls.visibility.controls.private.setValue(true);

          // Because the dialog component focus the last selected element on close, we need to move the focus to the now selected private radio button
          (
            this._document.querySelectorAll(`[data-cy=${this.radioInputDataCy}]`)[1] as HTMLElement
          ).focus();
        }
      });
  }
}
