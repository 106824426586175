import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleMenuComponentModule } from '@accredible-frontend-v2/new-components/menu';
import { NgModule } from '@angular/core';
import { AccredibleTableKebabButtonComponent } from './table-kebab-button.component';

@NgModule({
  imports: [
    AccredibleButtonComponentModule,
    AccredibleIconComponentModule,
    AccredibleMenuComponentModule,
  ],
  exports: [AccredibleTableKebabButtonComponent],
  declarations: [AccredibleTableKebabButtonComponent],
})
export class AccredibleTableKebabButtonModule {}
