import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccrediblePaginationComponentModule } from '@accredible-frontend-v2/new-components/pagination';
import {
  AccredibleTableColumn,
  AccredibleTableColumnType,
  AccredibleTableModule,
} from '@accredible-frontend-v2/new-components/table';
import { AccredibleToastService } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CdkMenuModule } from '@angular/cdk/menu';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { SortDirection } from '@angular/material/sort';
import { TranslocoDirective } from '@jsverse/transloco';
import { finalize, map, of } from 'rxjs';
import {
  Evaluator,
  EvaluatorInvitationStatus,
  ListEvaluatorsParams,
  LocalTeamMember,
} from '../../../../models/evaluator.model';
import { EvaluatorsService } from '../../../../services/evaluators.service';
import { TeamMembersFacadeService } from './list-team-members.facade';

const TRANSLATION_KEY = 'list-team-members';

enum TableColumnDef {
  NAME = 'name',
  EMAIL = 'email',
  ROLE = 'role',
  ACTIONS = 'actions',
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    AccredibleButtonComponentModule,
    AccredibleTableModule,
    AccrediblePaginationComponentModule,
    CdkMenuModule,
  ],
  selector: 'db-list-team-members',
  templateUrl: './list-team-members.component.html',
  styleUrls: ['./list-team-members.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListTeamMembersComponent implements OnInit {
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _teamMembersFacade = inject(TeamMembersFacadeService);
  private readonly _evaluatorsService = inject(EvaluatorsService);
  private readonly _toast = inject(AccredibleToastService);

  spotlightDirectoryId = input<number>();

  teamMembers = this._teamMembersFacade.teamMembers;
  pageMeta = this._teamMembersFacade.pageMeta;

  params = signal<ListEvaluatorsParams>({
    role: 'owner',
    page: 1,
    page_size: 12,
  });
  columnDef = TableColumnDef;
  invitationStatus = EvaluatorInvitationStatus;
  isInviting = signal<number>(null);

  displayedColumns: AccredibleTableColumn[] = [
    {
      def: TableColumnDef.NAME,
      width: '25%',
      label: this._language.selectTranslate(`${TRANSLATION_KEY}.name`),
      type: AccredibleTableColumnType.STRING,
    },
    {
      def: TableColumnDef.EMAIL,
      width: '25%',
      label: this._language.selectTranslate(`${TRANSLATION_KEY}.email`),
      type: AccredibleTableColumnType.STRING,
    },
    {
      def: TableColumnDef.ROLE,
      width: '25%',
      label: this._language.selectTranslate(`${TRANSLATION_KEY}.role`),
      type: AccredibleTableColumnType.CUSTOM_TEMPLATE,
    },
    {
      def: TableColumnDef.ACTIONS,
      width: '25%',
      label: of(''),
      type: AccredibleTableColumnType.CUSTOM_TEMPLATE,
    },
  ];

  sortDirection: SortDirection;
  sortActive: string;

  ngOnInit(): void {
    this._loadTeamMembers();
  }

  onPageChange(page: number): void {
    this.params.update((currentParams) => ({
      ...currentParams,
      page,
    }));
    this._loadTeamMembers();
  }

  onInvite(teamMember: LocalTeamMember): void {
    this.isInviting.set(+teamMember.id);
    this._evaluatorsService
      .inviteEvaluators(
        {
          owners: [{ issuer_login_id: +teamMember.id }],
        },
        this.spotlightDirectoryId(),
      )
      .pipe(
        map((response) => response.spotlight_directory_evaluator_profiles),
        finalize(() => {
          this.isInviting.set(null);
        }),
      )
      .subscribe({
        next: (evaluators) => this._onInviteSuccess(evaluators),
        error: () => this._toast.error(this._language.translate(`${TRANSLATION_KEY}.invite_error`)),
      });
  }

  onResendInvitation(teamMember: LocalTeamMember): void {
    this._evaluatorsService
      .resendInvitation(teamMember.evaluatorId, this.spotlightDirectoryId())
      .subscribe({
        next: () =>
          this._toast.success(
            this._language.translate(`${TRANSLATION_KEY}.resend_invitation_success`),
          ),
        error: () =>
          this._toast.error(this._language.translate(`${TRANSLATION_KEY}.resend_invitation_error`)),
      });
  }

  onCancelInvitation(teamMember: LocalTeamMember): void {
    this._evaluatorsService
      .deleteEvaluators([teamMember.evaluatorId], this.spotlightDirectoryId())
      .subscribe({
        next: () => this._onCancelInvitationSuccess(teamMember),
        error: () =>
          this._toast.error(this._language.translate(`${TRANSLATION_KEY}.cancel_invitation_error`)),
      });
  }

  private _onInviteSuccess(evaluators: Evaluator[]): void {
    this.teamMembers.update((currentTeamMembers) =>
      currentTeamMembers.map((member) => {
        const evaluator = evaluators.find(
          (evaluator) => evaluator.evaluator_profile.user.email === member.email,
        );
        return {
          ...member,
          evaluatorId: evaluator?.id,
          status: evaluator?.status || member.status,
        };
      }),
    );
    this._toast.success(this._language.translate(`${TRANSLATION_KEY}.invite_success`));
  }

  private _onCancelInvitationSuccess(teamMember: LocalTeamMember): void {
    this.teamMembers.update((currentTeamMembers) =>
      currentTeamMembers.map((member) =>
        member.id === +teamMember.id
          ? { ...member, status: EvaluatorInvitationStatus.NOT_INVITED }
          : member,
      ),
    );
    this._toast.success(this._language.translate(`${TRANSLATION_KEY}.cancel_invitation_success`));
  }

  private _loadTeamMembers(): void {
    this._teamMembersFacade.loadTeamMembers(this.params(), this.spotlightDirectoryId());
  }
}
