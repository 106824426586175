import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { isObservable, Observable, Subscribable } from 'rxjs';

@Pipe({ name: 'syncOrAsync', pure: false })
export class SyncOrAsyncPipe implements PipeTransform, OnDestroy {
  private _asyncPipe: AsyncPipe;

  constructor(private readonly _injector: Injector) {
    this._asyncPipe = new AsyncPipe(_injector.get(ChangeDetectorRef));
  }

  ngOnDestroy(): void {
    this._asyncPipe.ngOnDestroy();
  }

  transform<T>(value: Observable<T> | Subscribable<T> | Promise<T> | T): T {
    if (isObservable(value)) {
      return this._asyncPipe.transform<T>(value);
    }

    return <T>value;
  }
}
