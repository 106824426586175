import {
  CdkDragEnter,
  CdkDropList,
  CdkDropListGroup,
  DropListRef,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'accredible-tile-gallery',
  templateUrl: './tile-gallery.component.html',
  styleUrls: [`./tile-gallery.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileGalleryComponent implements AfterViewInit {
  @Input()
  items: { id: string | number; [key: string]: any }[];
  @Input()
  isCredentialOwner = false;
  @Input()
  fixedHeight: string;
  @Input()
  enableReorder = false;

  @ContentChild(TemplateRef)
  itemTemplateRef: TemplateRef<any>;

  // Start of drag and drop feature variables (reference link: https://stackblitz.com/edit/angular-nuiviw?file=src%2Fapp%2Fapp.component.html)

  @Output()
  reorder = new EventEmitter<any[]>();

  @ViewChild(CdkDropListGroup)
  listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild('placeholder', { read: CdkDropList })
  placeholder: CdkDropList;

  drop: CdkDropList;
  dropIndex: number;
  dragSource: DropListRef;
  dragSourceIndex: number;

  // End of drag and drop feature variables

  private static _indexOf(collection: HTMLCollection, node: HTMLElement): number {
    return Array.prototype.indexOf.call(collection, node);
  }

  // Start of drag and drop feature methods ----------

  ngAfterViewInit(): void {
    const placeholderEl = this.placeholder.element.nativeElement;
    placeholderEl.style.display = 'none';
    placeholderEl.parentNode.removeChild(placeholderEl);
  }

  onDrop(): void {
    if (!this.drop) {
      return;
    }

    const placeholderEl = this.placeholder.element.nativeElement;
    const parent = placeholderEl.parentNode;

    placeholderEl.style.display = 'none';
    parent.removeChild(placeholderEl);
    parent.appendChild(placeholderEl);
    parent.insertBefore(
      this.dragSource.data.element.nativeElement,
      parent.children[this.dragSourceIndex],
    );

    this.drop = null;
    this.dragSource = null;

    if (this.dragSourceIndex !== this.dropIndex) {
      // Save the initialOrderedItems in case the request fails
      const initialOrderedItems = [...this.items];

      moveItemInArray(this.items, this.dragSourceIndex, this.dropIndex);

      this.reorder.emit(initialOrderedItems);
    }
  }

  onEnter(event: CdkDragEnter): void {
    this.drop = event.container;
    const drag = event.item;

    if (this.drop === this.placeholder) {
      return;
    }

    const placeholderEl = this.placeholder.element.nativeElement;
    const dropEl = this.drop.element.nativeElement;
    this.dropIndex = TileGalleryComponent._indexOf(dropEl.parentNode.children, dropEl);

    let dragIndex = TileGalleryComponent._indexOf(
      dropEl.parentNode.children,
      drag.dropContainer.element.nativeElement,
    );
    if (dragIndex > -1) {
      // If the drag element was found it means that this event is being triggered for the first time after an item started being dragged
      this.dragSourceIndex = dragIndex;
      this.dragSource = drag.dropContainer._dropListRef;

      const sourceElement = this.dragSource.data.element.nativeElement;
      placeholderEl.style.width = sourceElement.clientWidth + 'px';
      placeholderEl.style.height = sourceElement.clientHeight + 'px';
      placeholderEl.style.display = '';

      // Remove drag element from the dom
      sourceElement.parentNode.removeChild(sourceElement);
    } else {
      // If the drag element was not found it means this is not the first time this event is being triggered after an item started being dragged,
      // the drag element has already been removed from the dom and the placeholder element was inserted instead,
      // so we want to look for the placeholder element
      dragIndex = TileGalleryComponent._indexOf(dropEl.parentNode.children, placeholderEl);
    }

    // Insert or move placeholder element in the dom
    dropEl.parentNode.insertBefore(
      placeholderEl,
      dragIndex < this.dropIndex ? dropEl.nextSibling : dropEl,
    );

    // Alert the placeholder that an item has moved into its container
    this.placeholder._dropListRef.enter(
      drag._dragRef,
      drag.element.nativeElement.offsetLeft,
      drag.element.nativeElement.offsetTop,
    );
  }

  // End of drag and drop feature methods ----------

  trackByFn(index: number, item: { id: string | number; [key: string]: any }): string | number {
    return item?.id || index;
  }
}
