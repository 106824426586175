import { emailListLimitedValidator } from '@accredible-frontend-v2/forms/validators';
import { ApiError } from '@accredible-frontend-v2/models';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleInputFeedbackModule } from '@accredible-frontend-v2/new-components/input-feedback';
import { AccredibleTextInputModule } from '@accredible-frontend-v2/new-components/text-input';
import { AccredibleToastService } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { finalize } from 'rxjs';
import {
  InviteEvaluatorsParams,
  InviteEvaluatorsResponse,
} from '../../../../models/evaluator.model';
import { EvaluatorsService } from '../../../../services/evaluators.service';

const TRANSLATION_KEY = 'invite-viewers';

interface InviteViewerForm {
  emails: FormControl<string>;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    AccredibleTextInputModule,
    AccredibleInputFeedbackModule,
    ReactiveFormsModule,
    AccredibleButtonComponentModule,
    AccredibleIconComponentModule,
    AccredibleTooltipModule,
  ],
  selector: 'db-invite-viewers',
  templateUrl: './invite-viewers.component.html',
  styleUrls: ['./invite-viewers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteViewersComponent implements OnInit {
  private readonly _toast = inject(AccredibleToastService);
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _evaluators = inject(EvaluatorsService);

  spotlightDirectoryId = input<number>();
  inviteSent = output<void>();

  formGroup: FormGroup<InviteViewerForm>;
  isLoading = signal(false);

  ngOnInit(): void {
    this._setupForm();
  }

  submit(): void {
    if (!this.formGroup.valid) {
      return;
    }

    this.isLoading.set(true);
    const { emails } = this.formGroup.getRawValue();
    const splitEmails = emails.split(',');
    const params: InviteEvaluatorsParams = {
      evaluators: splitEmails.map((value) => {
        return { email: value.trim() };
      }),
    };

    this._evaluators
      .inviteEvaluators(params, this.spotlightDirectoryId())
      .pipe(
        finalize(() => {
          this.isLoading.set(false);
        }),
      )
      .subscribe({
        next: (response: InviteEvaluatorsResponse) => {
          this._handleSuccess(response, emails.split(',').length);
        },
        error: (error: ApiError) => {
          this._handleErrors(error);
        },
      });
  }

  private _setupForm(): void {
    this.formGroup = new FormGroup({
      emails: new FormControl(null, [emailListLimitedValidator()]),
    });
  }

  private _handleSuccess(response: InviteEvaluatorsResponse, emailCount: number): void {
    if (response.errors?.length > 0) {
      const successful = emailCount - response.errors.length;
      const errors = response.errors.length;
      this._toast.info(
        this._language.translate(`${TRANSLATION_KEY}.success_and_error`, {
          success: successful,
          error: errors,
        }),
      );
    } else {
      this._toast.success(
        this._language.translate(`${TRANSLATION_KEY}.success`, {
          count: emailCount,
        }),
      );
    }
    this.formGroup.reset();
    this.inviteSent.emit();
  }

  private _handleErrors(response: ApiError): void {
    this._toast.error(
      this._language.translate(`${TRANSLATION_KEY}.error`, { count: response.errors.length }),
    );
  }
}
