import { NgModule } from '@angular/core';
import { TabComponent } from './tab/tab.component';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabsComponent } from './tabs.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';

@NgModule({
  imports: [TabComponent, TabContentComponent, TabsComponent, TabHeaderComponent],
  exports: [TabComponent, TabContentComponent, TabsComponent, TabHeaderComponent],
})
export class TabsComponentModule {}
