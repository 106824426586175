import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const domainValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // TODO: Allow spaces at the end, but implement trim on save in forms using this validator
    if (value) {
      // Source: variation of https://miguelmota.com/bytes/validate-domain-regex/
      const domainRegex = new RegExp(
        /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi,
      );

      if (!domainRegex.test(value)) {
        return { domain: true };
      }
    }

    return null;
  };
};
