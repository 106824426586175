<div *transloco="let t; read: 'delete-viewer-confirmation'"
  class="delete-viewer-confirmation-container">
  <div class="cancel-confirmation">
    <div class="left">
      <accredible-icon [icon]="'warning'" />

      <span class="font-s-semibold">{{ t('cancel_invitation', { count: deletionCount() }) }}</span>
    </div>

    <div class="buttons">
      <button accredible-button-outline
        size="small"
        color="danger"
        class="delete-button"
        (click)="delete($event)">
        {{ t('confirm_cancel_invitation') }}
      </button>

      <button accredible-button-outline
        size="small"
        class="keep-button"
        (click)="cancel($event)">
        {{ t('keep') }}
      </button>
    </div>
  </div>
</div>