import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { emailValidator } from './email.validator';

/**
 * Custom validator for a comma-separated list of emails
 * Validates:
 * - At least one email is provided (required)
 * - Maximum of 5 emails
 * - All emails are valid format
 * - Emails must be comma-separated
 * - No duplicate emails allowed
 */

function splitAndTrimEmails(value: string): string[] {
  return value
    .split(',')
    .map((email) => email.trim())
    .filter((email) => email !== '');
}

function validateEmailFormat(emails: string[]): ValidationErrors | null {
  const invalidEmails = emails.filter((email) => {
    const tempControl = new FormControl(email);
    return emailValidator()(tempControl) !== null;
  });

  if (invalidEmails.length > 0) {
    return {
      invalidEmails: true,
      invalidEmailsList: invalidEmails,
    };
  }

  return null;
}

function validateCommaDelimitation(value: string, emails: string[]): ValidationErrors | null {
  const trimmedValue = value.trim();
  if (trimmedValue.includes(' ') && emails.some((email) => email.includes(' '))) {
    return { missingCommas: true };
  }
  return null;
}

function validateDuplicateEmails(emails: string[]): ValidationErrors | null {
  const uniqueEmails = new Set(emails.map((email) => email.toLowerCase()));
  if (uniqueEmails.size < emails.length) {
    return { duplicateEmails: true };
  }
  return null;
}

export function emailListLimitedValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value || value.trim() === '') {
      return { required: true };
    }

    const emails = splitAndTrimEmails(value);

    if (emails.length > 5) {
      return { maxEmails: true };
    }

    const emailFormatErrors = validateEmailFormat(emails);
    if (emailFormatErrors) {
      return emailFormatErrors;
    }

    const commaDelimitationErrors = validateCommaDelimitation(value, emails);
    if (commaDelimitationErrors) {
      return commaDelimitationErrors;
    }

    const duplicateEmailErrors = validateDuplicateEmails(emails);
    if (duplicateEmailErrors) {
      return duplicateEmailErrors;
    }

    return null;
  };
}
