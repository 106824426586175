import { AccredibleObjectWithId, ApiErrorDetails } from '@accredible-frontend-v2/models';

export interface Evaluator {
  id: string;
  issuer_login_id: number;
  status: EvaluatorInvitationStatus;
  role: 'evaluator' | 'owner';
  evaluator_profile: {
    user: {
      email: string;
    };
  };
}

export interface LocalTeamMember extends AccredibleObjectWithId {
  evaluatorId: string;
  name: string;
  email: string;
  role: 'admin' | 'team_member';
  status: EvaluatorInvitationStatus;
}

export enum EvaluatorInvitationStatus {
  INVITATION_SENT = 'invitation_sent',
  INVITATION_SCHEDULED = 'invitation_scheduled',
  INVITATION_ACCEPTED = 'invitation_accepted',
  EMAIL_BOUNCED = 'email_bounced',
  NOT_INVITED = 'not_invited',
}

export interface ListEvaluatorsParams {
  role: 'evaluator' | 'owner';
  page: number;
  page_size: number;
  issuer_login_ids?: number[];
}

export interface InviteEvaluatorsParams {
  evaluators?: {
    email: string;
  }[];
  owners?: {
    issuer_login_id: number;
  }[];
}

export interface InviteEvaluatorsResponse {
  success?: boolean;
  errors?: ApiErrorDetails[];
  spotlight_directory_evaluator_profiles?: Evaluator[];
}
