import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccredibleInputFeedbackComponent } from './input-feedback.component';

@NgModule({
  declarations: [AccredibleInputFeedbackComponent],
  imports: [CommonModule, AccredibleIconComponentModule],
  exports: [AccredibleInputFeedbackComponent],
})
export class AccredibleInputFeedbackModule {}
