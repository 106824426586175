import { EmbeddingRouterLinkDirectiveModule } from '@accredible-frontend-v2/embedding';
import { AccredibleChipComponentModule } from '@accredible-frontend-v2/new-components/chip';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AccredibleBreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';
import { AccredibleBreadCrumb } from '../breadcrumbs/models/breadcrumbs.model';

@Component({
  standalone: true,
  selector: 'accredible-content-header, accredible-content-header[align-content-center]',
  imports: [
    CommonModule,
    RouterModule,
    TranslocoModule,
    EmbeddingRouterLinkDirectiveModule,
    AccredibleBreadcrumbsComponent,
    AccredibleIconComponentModule,
    AccredibleChipComponentModule,
  ],
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // ViewEncapsulation.None in order to be able to style child of filters
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleContentHeaderComponent {
  withMenu = input<boolean>(false);
  image = input<string>();
  title = input<string>();
  subtitle = input<string>();
  chipTitle = input<string>();
  withBreadcrumbs = input<boolean>(true);
  breadcrumbs = input<AccredibleBreadCrumb[]>([]);
  backNavigation = input<string | string[]>();
  v1BackNavigation = input<{ v1Route: string; hrefUrl: string }>();
  isLoading = input<boolean>(false);
}
