<ng-container *transloco="let t; read: 'credential-tile-status'">
  @if (credential().isCustomCredential) {
    <accredible-chip class="small unverified"
      [data]="t('unverified')"
      [icon]="unverifiedIcon"
    />

    <div class="sr-only">
      {{ t('unverified_credential_sr_only') }}
    </div>
  }

  @if (credential().isPrivate) {
    <accredible-chip class="small private"
      [data]="t('private')"
      [icon]="privateIcon"
    />

    <div class="sr-only">
      {{ t('private_credential_sr_only') }}
    </div>
  }

  @if (credential().isExpired) {
    <accredible-chip class="small expired"
      [data]="t('expired')"
      [icon]="expiredIcon"
    />

    <div class="sr-only">
      {{ t('expired_credential_sr_only') }}
    </div>
  }
</ng-container>
