<div class="breadcrumbs font-s-regular">

  @for (breadcrumb of breadcrumbs(); track breadcrumb.name; let last = $last) {
    @if (breadcrumb.routerLink || breadcrumb.embeddingHrefUrl) {
      <a [embeddingRouterLink]="breadcrumb.routerLink"
        [embeddingHrefUrl]="breadcrumb.embeddingHrefUrl!">
        {{ breadcrumb.name | async }}
      </a>
    } @else {
      <span class="title">{{ breadcrumb.name | async }}</span>
    }

    @if (!last) {
      <accredible-icon icon="chevron-right"/>
    }
  }

</div>
