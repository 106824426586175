import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const passwordValidator = (): ValidatorFn => {
  return ({ value }: AbstractControl): ValidationErrors | null => {
    if (value) {
      // Set error on control if validation fails
      return !isPasswordValid(value) ? { password: true } : null;
    }

    return null;
  };
};

export const isPasswordValid = (password: string): boolean => {
  // Minimum 10 and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()<>?_-])[A-Za-z\d!@#$%^&*()<>?_-]{10,64}$/;
  return passwordRegex.test(password);
};
