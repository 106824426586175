import { environment } from '@accredible-frontend-v2/envs';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-tile',
  templateUrl: './tile.component.html',
  styleUrls: [`./tile.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {
  @Input()
  isOwner = false;
}
