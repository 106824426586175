import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type FeedbackType = 'success' | 'error';

@Component({
  selector: 'accredible-input-feedback',
  templateUrl: './input-feedback.component.html',
  styleUrls: ['./input-feedback.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleInputFeedbackComponent {
  @Input()
  message: string;

  iconClass = 'warning';

  private _feedbackType: FeedbackType = 'error';

  get type(): FeedbackType {
    return this._feedbackType;
  }

  @Input()
  set type(type: FeedbackType) {
    this._feedbackType = type;

    switch (type) {
      case 'error':
        this.iconClass = 'warning';
        break;
      case 'success':
        this.iconClass = 'check1';
        break;
    }
  }
}
