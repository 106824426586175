import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const urlValidator = (requiredStr?: string): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    // TODO: Allow spaces at the end, but implement trim on save in forms using this validator
    if (value) {
      // Source: https://gist.github.com/dperini/729294
      const webUrlRegex = new RegExp(
        '^' +
          // protocol identifier (optional)
          // short syntax // still required
          '(?:(?:(?:https?|ftp):)?\\/\\/)?' +
          // user:pass BasicAuth (optional)
          '(?:\\S+(?::\\S*)?@)?' +
          '(?:' +
          // IP address exclusion
          // private & local networks
          '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
          '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
          '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broadcast addresses
          // (first & last IP address of each class)
          '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
          '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
          '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
          '|' +
          // host & domain names, may end with dot
          // can be replaced by a shortest alternative
          // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
          '(?:' +
          '(?:' +
          '[a-z0-9\\u00a1-\\uffff]' +
          '[a-z0-9\\u00a1-\\uffff_-]{0,62}' +
          ')?' +
          '[a-z0-9\\u00a1-\\uffff]\\.' +
          ')+' +
          // TLD identifier name, may end with dot
          '(?:[a-z\\u00a1-\\uffff]{2,}\\.?)' +
          ')' +
          // port number (optional)
          '(?::\\d{2,5})?' +
          // resource path (optional)
          '(?:[/?#]\\S*)?' +
          '$',
        'i',
      );

      if (requiredStr && !value.includes(requiredStr)) {
        return { url: true };
      } else if (!webUrlRegex.test(value)) {
        return { url: true };
      }
    }

    return null;
  };
};

export const linkedinUrlValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null;
    }

    // Specific LinkedIn URL validation
    // Matches patterns like:
    // https://www.linkedin.com/in/joão-doe
    // https://linkedin.com/in/username
    // www.linkedin.com/in/username
    // linkedin.com/in/username
    // https://www.linkedin.com/
    // linkedin.com
    const linkedinRegex = new RegExp(
      '^' +
        '(?:(?:https?:)?\\/\\/)?' + // Optional protocol
        '(?:www\\.)?' + // Optional www
        'linkedin\\.com' +
        '(?:\\/' + // Optional path starting with /
        '(?:(?:in|company)\\/' + // /in/ or /company/
        '[\\w\\u00C0-\\uFFFF\\-%]+)?' + // username/company name with international chars
        ')?' +
        '(?:\\/)?$', // Optional trailing slash
      'i',
    );

    return linkedinRegex.test(value) ? null : { linkedinUrl: true };
  };
};
