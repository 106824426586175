import { SortDirection } from '@angular/material/sort';
import { Observable } from 'rxjs';

export interface AccredibleTableColumn {
  def: string;
  width: string;
  // This is an Observable<string> in order to support switching between languages
  label?: Observable<string>;
  extraDef?: string;
  sort?: boolean;
  sortStart?: SortDirection;
  type?: AccredibleTableColumnType;
  toolTip?: Observable<string>;
}

export enum AccredibleTableColumnType {
  STRING,
  DATE,
  MONTH_DATE,
  CURRENCY,
  ROUTER_LINK,
  EXTERNAL_LINK,
  CUSTOM_TEMPLATE,
}
