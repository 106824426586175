import { AccrediblePageData } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { DashboardApiService } from '@dashboard/domain';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  Evaluator,
  InviteEvaluatorsParams,
  InviteEvaluatorsResponse,
  ListEvaluatorsParams,
} from '../models/evaluator.model';

@Injectable({
  providedIn: 'root',
})
export class EvaluatorsService extends DashboardApiService {
  private _getBaseUrl(spotlightDirectoryId: number): string {
    return `/v1/issuer/spotlight_directories/${spotlightDirectoryId}/spotlight_directory_evaluator_profiles`;
  }

  searchEvaluators(
    params: ListEvaluatorsParams,
    spotlightDirectoryId: number,
  ): Observable<AccrediblePageData<Evaluator>> {
    const url = `${this._getBaseUrl(spotlightDirectoryId)}/search`;
    return this._post(url, params).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  inviteEvaluators(
    params: InviteEvaluatorsParams,
    spotlightDirectoryId: number,
  ): Observable<InviteEvaluatorsResponse> {
    const url = this._getBaseUrl(spotlightDirectoryId);
    return this._post(url, params).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }

  resendInvitation(evaluatorId: string, spotlightDirectoryId: number): Observable<boolean> {
    const url = `${this._getBaseUrl(spotlightDirectoryId)}/${evaluatorId}/resend_email`;
    return this._post(url, {}).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }

  deleteEvaluators(params: string[], spotlightDirectoryId: number): Observable<boolean> {
    const url = `${this._getBaseUrl(spotlightDirectoryId)}/bulk_delete`;
    return this._delete(url, { spotlight_directory_evaluator_profiles: params }).pipe(
      map((res) => this._handleResponse(res, 'success')),
      catchError((res) => this._handleError(res)),
    );
  }
}
