import {
  BillingPeriod,
  ProductOffering,
  ProductOfferingKind,
  ProductOfferingPrice,
} from '../entities/db-product-offering.model';

const getDefaultProductOfferingPrice = (): ProductOfferingPrice => ({
  id: '1',
  stripe_price_id: 'price_1R1CdiJV8l1gZKvcDCXiEZeN',
  name: 'Monthly',
  billing_period: BillingPeriod.MONTHLY,
  amount: 45,
});

const getDefaultProductOffering = (): ProductOffering => ({
  id: '1',
  stripe_product_id: 'prod_Rv2i4rV57N4xMN',
  name: 'Launch plan',
  description: '$45/month | Up to 1 department',
  kind: ProductOfferingKind.BASE,
  prices: [
    getProductOfferingPrice({
      name: 'Monthly',
      billing_period: BillingPeriod.MONTHLY,
      amount: 45,
    }),
    getProductOfferingPrice({
      name: 'Yearly',
      billing_period: BillingPeriod.YEARLY,
      amount: 450,
    }),
  ],
});

export const getProductOfferingPrice = (
  productOfferingPrice?: Partial<ProductOfferingPrice>,
): ProductOfferingPrice => ({
  ...getDefaultProductOfferingPrice(),
  ...productOfferingPrice,
});

export const getProductOffering = (
  productOffering?: Partial<ProductOffering>,
): ProductOffering => ({
  ...getDefaultProductOffering(),
  ...productOffering,
});
