<div *transloco="let t; read: 'content-header'"
  class="content-header"
  [class.ghost]="isLoading()">

  <div class="info-actions"
    [class.has-breadcrumbs]="withBreadcrumbs()">
    <div class="info">
      @if (backNavigation()) {
        <button class="btn-back"
          data-cy="btn-back"
          [attr.aria-label]="t('navigate_back')"
          [routerLink]="backNavigation()">

          <accredible-icon icon="chevron-left"/>
        </button>
      } @else if (v1BackNavigation()) {
        <a class="btn-back"
          data-cy="btn-back"
          [attr.aria-label]="t('navigate_back')"
          [embeddingRoute]="v1BackNavigation().v1Route"
          [embeddingHrefUrl]="v1BackNavigation().hrefUrl">
          <accredible-icon icon="chevron-left"/>
        </a>
      }

      @if (image()) {
        <img [src]="image()"
          aria-hidden="true"
          alt="">
      }

      <ng-content select="[contentHeaderLeftSide]"/>

      <div class="breadcrumbs-title-subtitle">
        @if (withBreadcrumbs()) {
          <accredible-breadcrumbs [breadcrumbs]="breadcrumbs()"/>
        }

        @if (title()) {
          <h1 [class.font-xl-semibold]="withMenu()"
            [class.font-h-semibold]="!withMenu()"
            [class.with-chip]="chipTitle()">
            {{ title() }}

            @if (chipTitle()) {
              <accredible-chip [data]="chipTitle()!"/>
            }
          </h1>
        }

        <ng-content select="[contentHeaderTitle]"/>

        @if (subtitle()) {
          <div class="subtitle font-multiline-normal-regular">
            {{ subtitle() }}
          </div>
        }
      </div>
    </div>

    <div class="content-center">
      <ng-content select="[contentHeaderCenter]"/>
    </div>

    <div class="actions">
      <ng-content select="[contentHeaderActions]"/>
    </div>
  </div>

  <div class="filters"
    [class.with-back-navigation]="backNavigation() || v1BackNavigation()">
    <ng-content select="[contentHeaderFilters]"/>
  </div>
</div>
