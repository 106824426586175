<div [ngClass]="type"
  class="hint">
  <div class="hint-info-wrapper">
    <accredible-icon *ngIf="icon"
      [icon]="icon">
    </accredible-icon>

    <img *ngIf="image"
      [src]="image"
      alt="">

    <span [innerHTML]="content"
      class="font-multiline-normal-regular">
    </span>
  </div>

  <ng-content select="[hintAction]"></ng-content>
</div>
