<div class="credential-selection-list"
  infinite-scroll
  infiniteScrollContainer="mat-sidenav-content"
  [fromRoot]="true"
  (scrolled)="fetchMore.emit()">
  @for (credential of credentials(); track credential.id) {
    <accredible-credential-selection-tile
      [credential]="credential"
      [disableSelection]="credential.disabled"
      (selectionChange)="onSelectionChange(credential.id, $event, credential)"
    />
  }
</div>
