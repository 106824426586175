import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TileGalleryComponent } from './tile-gallery.component';

const MATERIAL_MODULES = [MatIconModule];

@NgModule({
  imports: [CommonModule, DragDropModule, ...MATERIAL_MODULES],
  exports: [TileGalleryComponent],
  declarations: [TileGalleryComponent],
  providers: [],
})
export class AccredibleTileGalleryModule {}
