<ng-container *transloco="let t; read: 'nc-pagination'">
  <span *ngIf="!useCustomViewingFromTo else customViewingFromTo">
    {{ pageMeta.total_count > 0 ? t('viewing_from_to', viewingFromTo) : t('no_results') }}
  </span>

  <ng-template #customViewingFromTo>
    <ng-content select="[viewingFromTo]"></ng-content>
  </ng-template>

  <div class="right">
    <span *ngIf="!useCustomPageFromTo else customPageFromTo">
      {{ pageMeta.total_count > 0 ? t('page_from_to', pageFromTo) : '' }}
    </span>

    <ng-template #customPageFromTo>
      <ng-content select="[pageFromTo]"></ng-content>
    </ng-template>

    <div class="actions">
      <button accredible-button-icon
        [attr.aria-label]="t('aria_prev')"
        [size]="'small'"
        [color]="'natural'"
        [icon]="'chevron-left'"
        [iconSize]="'8px'"
        [disabled]="!pageMeta.prev_page"
        (click)="pageChange.emit(pageMeta.prev_page)">
      </button>

      <button accredible-button-icon
        [attr.aria-label]="t('aria_next')"
        [size]="'small'"
        [color]="'natural'"
        [icon]="'chevron-right'"
        [iconSize]="'8px'"
        [disabled]="!pageMeta.next_page"
        (click)="pageChange.emit(pageMeta.next_page)">
      </button>
    </div>
  </div>
</ng-container>
