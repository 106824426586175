<div class="delete-single-viewer-container">
  @if (!confirmingDeletion()) {
    <button accredible-button-icon
      icon="delete"
      size="small"
      color="natural"
      (click)="toggleRowDelete($event)">
    </button>
  } @else {
    <db-delete-viewer-confirmation (confirmDeletion)="deleteInvitation.emit(this.viewerId())"
      (cancelDeletion)="toggleRowDelete()" />
  }
</div>