import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'accredible-tab-content',
  template: `
    <ng-template>
      <ng-content></ng-content>
    </ng-template>
  `,
})
export class TabContentComponent {
  @ViewChild(TemplateRef)
  content: TemplateRef<any>;

  @Input()
  identifier = `tab-content-${uuidv4()}`;
}
