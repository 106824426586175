import { environment } from '@accredible-frontend-v2/envs';
import { filter, Observable, tap, throwError } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { WebSocketMessageEvent } from './models/websocket.model';

export class AccredibleWebSocketService {
  private readonly _maxReconnectAttempts = 5;

  WEBSOCKET_IDENTIFIER: string;

  private _WEBSOCKET_PROTOCOL = environment.name === 'local' ? 'ws' : 'wss';
  private readonly _WEBSOCKET_URL = `${environment.socketUrl.replace(
    /^https?/,
    this._WEBSOCKET_PROTOCOL,
  )}/cable`;
  private _WEBSOCKET_CHANNEL: string;
  private _connection$: WebSocketSubject<WebSocketMessageEvent>;
  private _reconnectAttempts = 0;

  constructor(private readonly _departmentId: number, private readonly _sessionToken: string) {
    this._WEBSOCKET_CHANNEL = 'BulkProgressChannel'; // default channel
    this.WEBSOCKET_IDENTIFIER = `{"channel":"${this._WEBSOCKET_CHANNEL}"}`;
  }

  connect(channel: string): Observable<WebSocketMessageEvent> {
    try {
      if (!channel) {
        throw Error('Channel name is required');
      }

      this._WEBSOCKET_CHANNEL = channel;
      this.WEBSOCKET_IDENTIFIER = `{"channel":"${this._WEBSOCKET_CHANNEL}"}`;

      if (!this._connection$) {
        const webSocketUrl = `${this._WEBSOCKET_URL}?token=${this._sessionToken}&organization_id=${this._departmentId}`;
        this._connection$ = this._setupConnection(webSocketUrl);
      }

      // Subscribe to the channel
      this._connection$.next({
        command: 'subscribe',
        identifier: this.WEBSOCKET_IDENTIFIER,
      });

      return this._connection$.pipe(
        filter((message) => message.type !== 'ping'),
        tap({
          next: (message) => {
            // Logs disconnect reasons (WS closeObserver doesn't return close reason)
            if (message.type === 'disconnect') {
              console.error('WS: Error', message.reason);
            }
          },
          error: (error) => {
            console.error('WS: Error:', error);
            this._handleReconnection();
          },
          complete: () => {
            console.log('WS: Connection closed');
            this._reconnectAttempts = 0;
            this._connection$ = null;
          },
        }),
      );
    } catch (error) {
      console.error('WS: Error establishing connection', error);
      return throwError(() => new Error('WS: Failed to establish connection'));
    }
  }

  closeConnection(): void {
    if (this._connection$) {
      this._connection$.complete();
      this._connection$ = null;
    }
  }

  private _setupConnection(webSocketUrl: string): WebSocketSubject<WebSocketMessageEvent> {
    return webSocket({
      url: webSocketUrl,
      closeObserver: {
        next: (closeEvent) => {
          if (!closeEvent.wasClean) {
            this._handleReconnection();
          }
        },
      },
    });
  }

  private _handleReconnection(): void {
    if (this._reconnectAttempts < this._maxReconnectAttempts) {
      this._reconnectAttempts++;
      console.log(
        `WS: Attempting to reconnect (${this._reconnectAttempts}/${this._maxReconnectAttempts})`,
      );

      this.closeConnection();

      // Create new connection and resubscribe
      const webSocketUrl = `${this._WEBSOCKET_URL}?token=${this._sessionToken}&organization_id=${this._departmentId}`;
      this._connection$ = this._setupConnection(webSocketUrl);

      // Resubscribe to the channel
      this._connection$.next({
        command: 'subscribe',
        identifier: this.WEBSOCKET_IDENTIFIER,
      });
    } else {
      console.error('WS: Max reconnection attempts reached');
      this.closeConnection();
    }
  }
}
