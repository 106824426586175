import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const idRequiredValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value?.id) {
      return null;
    }
    return { idRequired: true };
  };
};
