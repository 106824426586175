import { AccrediblePageMeta } from '@accredible-frontend-v2/models';
import { Injectable, inject, signal } from '@angular/core';
import { TeamMember, TeamMembersService } from '@dashboard/domain';
import { Observable, map, switchMap } from 'rxjs';
import {
  Evaluator,
  EvaluatorInvitationStatus,
  ListEvaluatorsParams,
  LocalTeamMember,
} from '../../../../models/evaluator.model';
import { EvaluatorsService } from '../../../../services/evaluators.service';

const GHOST_TEAM_MEMBERS = Array.from({ length: 12 }, () => ({}));

@Injectable({
  providedIn: 'root',
})
export class TeamMembersFacadeService {
  private readonly _teamMembers = inject(TeamMembersService);
  private readonly _evaluators = inject(EvaluatorsService);

  teamMembers = signal<LocalTeamMember[]>(GHOST_TEAM_MEMBERS as LocalTeamMember[]);
  pageMeta = signal<AccrediblePageMeta>({} as AccrediblePageMeta);

  loadTeamMembers(params: ListEvaluatorsParams, spotlightDirectoryId: number): void {
    this._teamMembers
      .loadTeamMembers({
        page: params.page,
        page_size: params.page_size,
        entity: 'corporation',
        distinct_issuer_logins: true,
      })
      .pipe(
        switchMap((teamMembersPageData) =>
          this._loadEvaluators(
            teamMembersPageData.organization_permissions,
            params,
            spotlightDirectoryId,
          ).pipe(
            map((teamMembers) => ({
              teamMembers,
              pageMeta: teamMembersPageData.meta,
            })),
          ),
        ),
      )
      .subscribe(({ teamMembers, pageMeta }) => {
        this.teamMembers.set(teamMembers);
        this.pageMeta.set(pageMeta);
      });
  }

  private _loadEvaluators(
    organizationPermissions: TeamMember[],
    params: ListEvaluatorsParams,
    spotlightDirectoryId: number,
  ): Observable<LocalTeamMember[]> {
    const issuerLoginIds = organizationPermissions.map((teamMember) => teamMember.issuer_login.id);

    return this._evaluators
      .searchEvaluators(
        {
          ...params,
          // page is always 1 because otherwise if we are in page 2 and some evaluators have been invited they will not be found
          page: 1,
          issuer_login_ids: issuerLoginIds,
        },
        spotlightDirectoryId,
      )
      .pipe(
        map((evaluatorsPageData) =>
          this._mapToLocalTeamMembers(
            evaluatorsPageData.spotlight_directory_evaluator_profiles,
            organizationPermissions,
          ),
        ),
      );
  }

  private _mapToLocalTeamMembers(
    evaluators: Evaluator[],
    organizationPermissions: TeamMember[],
  ): LocalTeamMember[] {
    return organizationPermissions.map((teamMember) => {
      const evaluator = evaluators.find(
        (evaluator) => evaluator.issuer_login_id === teamMember.issuer_login.id,
      );

      return {
        id: teamMember.issuer_login.id,
        evaluatorId: evaluator?.id,
        name: teamMember.issuer_login.name,
        email: teamMember.issuer_login.email,
        role: teamMember.admin ? 'admin' : 'team_member',
        status: evaluator?.status || EvaluatorInvitationStatus.NOT_INVITED,
      };
    });
  }
}
