import { AccredibleChipComponentModule } from '@accredible-frontend-v2/components/chip';
import {
  AccredibleIcon,
  AccredibleIconType,
  EarningCriteria,
  EarningCriteriaTypeValues,
} from '@accredible-frontend-v2/models';
import { AccredibleUrlHelper } from '@accredible-frontend-v2/utils/url-helper';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  selector: 'accredible-earning-criteria',
  templateUrl: './earning-criteria.component.html',
  styleUrls: ['./earning-criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // ViewEncapsulation.None needed to style the accredible-chip content
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, AccredibleChipComponentModule, TranslocoModule],
})
export class EarningCriteriaComponent {
  earningCriteriaRequired: EarningCriteria[] = [];
  earningCriteriaOptional: EarningCriteria[] = [];

  @Input()
  set earningCriteria(criteria: EarningCriteria[]) {
    if (!criteria) {
      return;
    }
    this.earningCriteriaRequired = this._filterAndTransformCriteria(criteria);
    this.earningCriteriaOptional = this._filterAndTransformCriteria(criteria, false);
  }

  getEarningCriteriaIcon(criteria: EarningCriteria): AccredibleIcon {
    return {
      name: EarningCriteriaTypeValues[criteria.kind],
      type: AccredibleIconType.NEW_ACC,
    };
  }

  private _filterAndTransformCriteria(
    criteria: EarningCriteria[],
    required = true,
  ): EarningCriteria[] {
    return criteria
      .filter((criteria) => criteria.required === required)
      .map((criteria) => (criteria.url ? this._parseExternalURL(criteria) : criteria));
  }

  private _parseExternalURL(criteria: EarningCriteria): EarningCriteria {
    return {
      ...criteria,
      url: AccredibleUrlHelper.createUrl(criteria.url, '').href,
    };
  }
}
