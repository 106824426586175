import { AccredibleLinkComponentModule } from '@accredible-frontend-v2/new-components/link';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'accredible-read-more',
  standalone: true,
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AccredibleLinkComponentModule],
})
export class AccredibleReadMoreComponent {
  title = input<string>('');
  subtitle = input<string>('');
  url = input<string>('');
  urlLabel = input<string>('');
  ariaLabel = input<string>('');
}
