import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const skillCategoryValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value === '' || control.value === null) {
      return null;
    } else if (control.value?.id) {
      return null;
    }
    return { skillCategory: true };
  };
};
