import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmailValid } from './email.validator';

export const emailListValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      // Set error on control if validation fails
      // Check if string is a list of valid emails
      if (value.includes(',')) {
        const testedEmailArray = value.split(',').map((email: string) => {
          return isEmailValid(email.trim());
        });
        return testedEmailArray.includes(false) ? { emailList: true } : null;
      }

      // If not a list check if it is a valid email
      return !isEmailValid(value) ? { emailList: true } : null;
    }

    return null;
  };
};
