import { AccredibleTooltipDirection } from '@accredible-frontend-v2/new-components/tooltip';
import { Component, input, OnDestroy, output, TemplateRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';

interface PremiumFeature {
  tooltipText?: string;
  tooltipLink?: string;
}

export interface AccredibleRadioGroupOption {
  value: string | boolean;
  label: string | Observable<string>;
  disabled?: boolean;
  premiumFeature?: PremiumFeature;
  hint?: string;
  image?: string;
  extendedDescription?: string | Observable<string>;
  preview?: TemplateRef<any>;
  showPreviewOnSelection?: boolean;
}

let nextUniqueId = 0;
let nextUniqueDataCy = 0;

@Component({
  selector: 'accredible-radio-input',
  templateUrl: './radio-input.component.html',
  styleUrls: ['./radio-input.component.scss'],
  // We are not using ChangeDetectionStrategy.OnPush because this input component
  // does not detect changes when formGroup.markAllAsTouched() is triggered
})
export class AccredibleRadioInputComponent implements OnDestroy {
  formControlRef = input<UntypedFormControl>();
  label = input<string>();
  hint = input<string>();
  required = input(false);
  options = input<AccredibleRadioGroupOption[]>();
  direction = input<'horizontal' | 'vertical'>('horizontal');
  optionContainer = input(false);
  containerMaxWidth = input<string>('375px');
  tooltipTitle = input<string>();
  tooltip = input<string>();
  dataCy = input<string>();
  noError = input(false);
  sameWidthForAllOptions = input(false);

  valueChange = output<string>();

  optionId = `radio-group-${nextUniqueDataCy++}`;
  radioGroupLabelId = `accredible-radio-group-${nextUniqueId++}`;

  tooltipDirection = AccredibleTooltipDirection;

  ngOnDestroy(): void {
    nextUniqueDataCy = 0;
  }
}
