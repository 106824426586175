import { AccredibleChipComponentModule } from '@accredible-frontend-v2/components/chip';
import {
  AccredibleIcon,
  AccredibleIconType,
  WalletCredential,
} from '@accredible-frontend-v2/models';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  standalone: true,
  imports: [CommonModule, AccredibleChipComponentModule, TranslocoModule],
  selector: 'accredible-credential-tile-status',
  templateUrl: 'credential-tile-status.component.html',
  styleUrls: ['./credential-tile-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialTileStatusComponent {
  credential = input<WalletCredential>();

  unverifiedIcon: AccredibleIcon = {
    type: AccredibleIconType.ACC,
    name: 'acc-icon-unverified',
    rtlMirror: true,
  };
  privateIcon: AccredibleIcon = {
    type: AccredibleIconType.MAT,
    name: 'visibility_off',
  };
  expiredIcon: AccredibleIcon = {
    type: AccredibleIconType.MAT,
    name: 'warning',
  };
}
