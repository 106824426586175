import { AccredibleObjectWithId } from '@accredible-frontend-v2/models';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { SelectionChange, SelectionModel } from '@angular/cdk/collections';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { Sort, SortDirection } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { AccredibleTableColumn, AccredibleTableColumnType } from './table.model';

const SELECT_COLUMN = { def: 'select' };

@UntilDestroy()
@Component({
  selector: 'accredible-table',
  templateUrl: './table.component.html',
  styleUrls: [`./table.component.scss`],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleTableComponent implements OnChanges {
  @Input()
  dataSource: AccredibleObjectWithId[];
  @Input()
  columns: AccredibleTableColumn[];
  @Input()
  ariaLabel: string | null = null;
  @Input()
  allowRowSelection = false;
  @Input()
  allowMultiSelect = false;
  @Input()
  allowMultiSelectValidation = false;
  @Input()
  initialSelection: (string | number)[] = [];
  @Input()
  noBorder = false;
  @Input()
  emptyTableTitle: string;
  @Input()
  emptyTableMessage = this._language.translate('accredible-table.no_results');
  @Input()
  emptyTableImage: string;
  @Input()
  sortActive: string;
  @Input()
  sortDirection: SortDirection;
  @Input()
  selectionDisabled = false;

  @Output()
  sortChange = new EventEmitter<Sort>();
  @Output()
  rowSelect = new EventEmitter();
  @Output()
  selectionChange = new EventEmitter<SelectionChange<string | number>>();

  @ContentChild(TemplateRef)
  customCellTemplateRef: TemplateRef<HTMLElement>;

  languageCode$ = this._language.languageCode$;

  tableColumnType = AccredibleTableColumnType;
  selection: SelectionModel<string | number>;

  constructor(private readonly _language: AccredibleLanguageService) {}

  get displayedColumns(): string[] {
    const selectColumn = this.allowMultiSelect ? [SELECT_COLUMN] : [];
    return [...selectColumn, ...this.columns].map((column) => column.def);
  }

  get availableRowsForSelection(): AccredibleObjectWithId[] {
    return this.allowMultiSelectValidation
      ? this.dataSource.filter((row) => !row.disabled)
      : this.dataSource;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['dataSource']) {
      this._handleSelection();
    }
  }

  onSortChange(sort: Sort): void {
    this.sortChange.emit(sort);
  }

  onRowSelect(row: AccredibleObjectWithId): void {
    if (!this.allowRowSelection || (this.allowMultiSelectValidation && row.disabled)) {
      return;
    }
    this.selection.toggle(row.id);
    this.rowSelect.emit(row);
  }

  trackById(
    index: number,
    rowEl: { id: string | number; [key: string]: unknown },
  ): string | number {
    return rowEl.id;
  }

  // Whether the number of selected elements matches the total number of rows available for selection.
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.availableRowsForSelection.length;
    return numSelected == numRows;
  }

  // Selects all rows if they are not all selected; otherwise clear selection.
  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.selection.select(...this.availableRowsForSelection.map((row) => row.id));
  }

  private _handleSelection(): void {
    if (this.selectionDisabled) {
      return;
    }

    if (this.selection) {
      this.selection.changed.complete();
    }

    this.selection = new SelectionModel(this.allowMultiSelect, this.initialSelection);
    this.selection.changed
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((selectionChange) => {
        this.selectionChange.emit(selectionChange);
      });
  }
}
