import {
  DirectoryDepartment,
  DirectorySettings,
  EligibilitySource,
} from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { DashboardApiService } from '@dashboard/domain';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DirectorySettingsApiService extends DashboardApiService {
  // Mock example - this._get('/directory_settings.json', true)
  loadDirectorySettings(directoryId: number): Observable<DirectorySettings> {
    const url = `/v1/dashboard/spotlight_directories/${directoryId}`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'spotlight_directory')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock example - this._get('/directory_settings_groups.json', true)
  loadDirectoryGroups(): Observable<EligibilitySource[]> {
    const url = `/v1/dashboard/groups`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'groups')),
      catchError((res) => this._handleError(res)),
    );
  }

  // Mock example - this._get('/directory_settings_departments.json', true)
  loadDirectoryDepartments(): Observable<DirectoryDepartment[]> {
    const url = `/v1/dashboard/departments`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'departments')),
      catchError((res) => this._handleError(res)),
    );
  }

  editDirectorySettings(
    directoryId: number,
    directorySettings: DirectorySettings,
  ): Observable<DirectorySettings> {
    const url = `/v1/dashboard/spotlight_directories/${directoryId}`;

    return this._put(url, { spotlight_directory: directorySettings }).pipe(
      map((res) => this._handleResponse(res, 'spotlight_directory')),
      catchError((res) => this._handleError(res)),
    );
  }
}
