<div class="acc-tile">
  <!-- Used for drag and drop feature -->
  <ng-content select="[cdkDragHandle]"></ng-content>

  <div class="tile-body"
    [ngClass]="!isOwner ? 'no-actions' : ''">
    <ng-content select="[tileBody]"></ng-content>
  </div>

  <div class="tile-status">
    <ng-content select="[tileStatus]"></ng-content>
  </div>

  <div *ngIf="isOwner"
    class="tile-actions">
    <!-- Tile actions should be always buttons -->
    <ng-content select="[tileActions]"></ng-content>
  </div>
</div>
