import { ButtonColor, ButtonType } from '@accredible-frontend-v2/new-components/button';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  model,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { AccredibleConfirmDialogData } from './models/confirm-dialog.model';

@Component({
  selector: 'accredible-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // we are using ViewEncapsulation.None in order to be able to style the overlay backdrop
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleConfirmDialogComponent {
  private readonly _data = inject<AccredibleConfirmDialogData>(DIALOG_DATA);
  private readonly _dialogRef = inject(DialogRef);
  private readonly _language = inject(AccredibleLanguageService);

  confirmDialogTitle = model<string>();
  confirmDialogImage = model<string>();
  confirmDialogText = model<string>();
  actionBtnText = model(this._language.translate('new-confirm-dialog.ok'));
  actionBtnColor = model<ButtonColor>('danger');
  actionBtnType = model<ButtonType>('solid');
  actionBtnDisabled = input(false);
  cancelBtnText = model(this._language.translate('new-confirm-dialog.cancel'));
  cancelBtnColor = model<ButtonColor>('natural');
  cancelBtnType = model<ButtonType>('solid');
  checkboxText = model<string>();
  isLoading = input(false);

  actionBtnClicked = output<boolean>();

  checkboxFormControl = new FormControl<boolean>(false);

  constructor() {
    if (!this._data) {
      return;
    }

    this.confirmDialogTitle.set(this._data.title);
    this.confirmDialogImage.set(this._data.image);
    this.confirmDialogText.set(this._data.text);
    this.actionBtnText.set(this._data.btnText || this.actionBtnText());
    this.actionBtnColor.set(this._data.btnColor || this.actionBtnColor());
    this.actionBtnType.set(this._data.btnType || this.actionBtnType());
    this.checkboxText.set(this._data.checkboxText || this.checkboxText());
    // When using dialog.service.ts confirm method to open this dialog,
    // if you want to hide the cancel button you can send null or '',
    // if you want the default "Cancel" text you should send nothing (undefined)
    this.cancelBtnText.set(
      this._data.cancelBtnText === undefined
        ? this.cancelBtnText()
        : this._data.cancelBtnText || null,
    );
    this.cancelBtnColor.set(this._data.cancelBtnColor || this.cancelBtnColor());
    this.cancelBtnType.set(this._data.cancelBtnType || this.cancelBtnType());
  }

  confirm(): void {
    if (this._data?.title) {
      this._dialogRef.close(true);
    } else {
      this.actionBtnClicked.emit(true);
    }
  }

  cancel(): void {
    this._dialogRef.close(false);
  }
}
