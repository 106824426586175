import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'accredible-tab-header',
  templateUrl: 'tab-header.component.html',
  styleUrls: ['./tab-header.component.scss'],
})
export class TabHeaderComponent {
  @ViewChild(TemplateRef)
  header: TemplateRef<any>;

  @Input()
  active = false;
  @Input()
  disabled = false;
  @Input()
  dataCy: string;
  @Input()
  identifier = `tab-header-${uuidv4()}`;

  @Output()
  tabSelected: EventEmitter<void> = new EventEmitter<void>();
}
