<div *transloco="let t; read: 'credential-selection-tile'"
  class="credential-selection-tile"
  role="button"
  [attr.tabindex]="isDisabled() ? -1 : 0"
  [attr.aria-pressed]="isSelected()"
  [ngClass]="{ 'selected': isSelected(), 'disabled': isDisabled() }"
  [accredibleTooltip]="isDisabled() ? t('disabled_selection') : null"
  (keydown.space)="toggleSelection()"
  (click)="toggleSelection()">

  <div class="icon-wrapper">
    @if (isSelected()) {
      <accredible-icon data-cy="credential-selected" icon="check"/>
    }
  </div>

  <accredible-credential-tile-status [credential]="credential()"/>

  <accredible-credential-tile-content class="tile no-hover"
    [credential]="credential()"
  />
</div>

