import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { SIDENAV_DATA, SidenavRef } from '@accredible-frontend-v2/services/sidenav';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { InviteViewersComponent } from './components/invite-viewers/invite-viewers.component';
import { ListViewersComponent } from './components/list-viewers/list-viewers.component';

@Component({
  standalone: true,
  imports: [
    TranslocoDirective,
    AccredibleIconComponentModule,
    AccredibleButtonComponentModule,
    InviteViewersComponent,
    ListViewersComponent,
  ],
  templateUrl: './viewers-sidenav.component.html',
  styleUrls: ['./viewers-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewersSidenavComponent {
  private readonly _sidenavRef = inject(SidenavRef);
  readonly data = inject<{ id: number }>(SIDENAV_DATA);

  onInviteForm = signal(false);

  close(): void {
    this._sidenavRef.close();
  }
}
