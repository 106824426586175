import { AccredibleRadioInputComponentModule } from '@accredible-frontend-v2/new-components/radio-input';
import { FormSectionComponentModule } from '@accredible-frontend-v2/new-components/ui-common';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from '@angular/core';
import { ControlContainer, FormGroup } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { HintComponentModule } from '@dashboard/shared/components/hint/hint.component.module';
import { TranslocoDirective } from '@jsverse/transloco';
import { DirectoryVisibilityForm } from '../visibility-settings/visibility-settings.component';

enum ProfileShow {
  ANY = 'any',
  ORGANIZATION = 'corporation',
  ELIGIBLE = 'eligible',
}

enum TranslationKey {
  PRIVATE = 'profile-visibility-settings.form.users_visible_by_default.private',
  PUBLIC = 'profile-visibility-settings.form.users_visible_by_default.public',
  ALL_ISSUERS = 'profile-visibility-settings.form.credential_visibility.any',
  ONLY_ORGANIZATION = 'profile-visibility-settings.form.credential_visibility.organization',
  ONLY_ELIGIBLE = 'profile-visibility-settings.form.credential_visibility.eligible',
  HIDE = 'profile-visibility-settings.form.include_expired_credentials.hide',
  SHOW = 'profile-visibility-settings.form.include_expired_credentials.show',
  ADDITIONAL_LINKS_HIDE = 'profile-visibility-settings.form.additional_links.hide',
  ADDITIONAL_LINKS_SHOW = 'profile-visibility-settings.form.additional_links.show',
}

@Component({
  standalone: true,
  imports: [
    TranslocoDirective,
    FormSectionComponentModule,
    HintComponentModule,
    AccredibleRadioInputComponentModule,
  ],
  selector: 'db-profile-visibility-settings',
  templateUrl: './profile-visibility-settings.component.html',
  styleUrls: ['./profile-visibility-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileVisibilitySettingsComponent implements OnInit {
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _controlContainer = inject(ControlContainer);

  privateSpotlightFF = input<boolean>(false);
  spotlightProfileRedesignFF = input<boolean>(false);

  formGroup: FormGroup<DirectoryVisibilityForm>;

  // Profiles are by default options
  readonly usersVisibleByDefaultOptions = [
    { value: true, label: this._language.selectTranslate(TranslationKey.PUBLIC) },
    { value: false, label: this._language.selectTranslate(TranslationKey.PRIVATE) },
  ];

  // Profiles show options
  readonly credentialVisibilityOptions = [
    { value: ProfileShow.ANY, label: this._language.selectTranslate(TranslationKey.ALL_ISSUERS) },
    {
      value: ProfileShow.ORGANIZATION,
      label: this._language.selectTranslate(TranslationKey.ONLY_ORGANIZATION),
    },
    {
      value: ProfileShow.ELIGIBLE,
      label: this._language.selectTranslate(TranslationKey.ONLY_ELIGIBLE),
    },
  ];

  // Expired options
  readonly expiredOptions = [
    { value: false, label: this._language.selectTranslate(TranslationKey.HIDE) },
    { value: true, label: this._language.selectTranslate(TranslationKey.SHOW) },
  ];

  // Additional links options
  readonly additionalLinksOptions = [
    { value: false, label: this._language.selectTranslate(TranslationKey.ADDITIONAL_LINKS_HIDE) },
    { value: true, label: this._language.selectTranslate(TranslationKey.ADDITIONAL_LINKS_SHOW) },
  ];

  ngOnInit(): void {
    this.formGroup = this._controlContainer.control as FormGroup;
  }
}
