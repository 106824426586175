import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export type HintType = 'info' | 'success' | 'warning' | 'danger' | 'neutral';

@Component({
  selector: 'db-hint, db-hint[no-margin], db-hint[no-border]',
  templateUrl: './hint.component.html',
  styleUrls: ['./hint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HintComponent {
  @Input()
  icon: string;
  @Input()
  type: HintType = 'info'; // corresponds to the class name applied
  @Input()
  content: string;
  @Input()
  image: string;
}
