<div *transloco="let t; read: 'team-members-sidenav'"
  class="sidenav">
  <div class="header">
    <div class="title">
      <div class="top-row">
        <div class="left">
          <div class="font-normal-semibold">
            {{ t('org_team_members') }}
          </div>
        </div>

        <div class="action-buttons">
          <button accredible-button-flat
            class="btn-close"
            size="small"
            color="natural"
            iconSize="14px"
            icon="close"
            (click)="close()">
          </button>
        </div>
      </div>

      <div class="subtitle">
        <span class="font-s-regular">
          {{ t('org_team_members_text') }}
        </span>
      </div>
    </div>
  </div>

  <db-list-team-members [spotlightDirectoryId]="data.id"/>
</div>
