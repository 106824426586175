import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { TabContentComponent } from './tab-content/tab-content.component';
import { TabHeaderComponent } from './tab-header/tab-header.component';
import { TabComponent } from './tab/tab.component';

@Component({
  standalone: true,
  imports: [CommonModule, TabComponent, TabContentComponent, TabHeaderComponent],
  selector: 'accredible-tabs',
  templateUrl: 'tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterViewInit {
  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  @ViewChildren('headerTabs')
  headerTabs: QueryList<ElementRef>;

  // Renders all tab panels and hides inactive ones
  @Input()
  renderHiddenTabs = false;

  @Input()
  label = `tablist-${uuidv4()}`;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * Handles set up of the tabs when the component first loads
   */
  ngAfterViewInit(): void {
    const activeTab = this.tabs?.find((tab) => tab.tabHeader.active === true);

    // if no tab is active, set the first
    if (!activeTab) {
      this.tabs.first.tabHeader.active = true;
    }

    this.changeDetectorRef.detectChanges();
  }

  /**
   * Updates the active tab when selected
   */
  selectTab(index: number): void {
    this.tabs?.forEach((tabComponent: TabComponent, tabIndex: number) => {
      tabComponent.tabHeader.active = tabIndex === index;
    });
  }

  /**
   * handles the selection of a new tab when a relevant keypress is detected
   */
  switchTab(index: number): void {
    const numTabs = this.tabs.length;

    // Wrap around the index if needed
    if (index < 0) {
      index = numTabs - 1;
    } else if (index >= numTabs) {
      index = 0;
    }
    // select the tab and focus it
    this.selectTab(index);

    const tabHeaders = this.headerTabs.toArray();
    const tabs = this.tabs.toArray();

    // Focus the tab header and trigger tabSelected emitter
    tabHeaders[index].nativeElement.focus();
    tabs[index].tabHeader.tabSelected.emit();
  }
}
