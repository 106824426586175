import { ButtonColor, ButtonType } from '@accredible-frontend-v2/new-components/button';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { DialogRef } from '@angular/cdk/dialog';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'accredible-dialog-template',
  templateUrl: './dialog-template.component.html',
  styleUrls: ['./dialog-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // we are using ViewEncapsulation.None in order to be able to style the dialog
  encapsulation: ViewEncapsulation.None,
})
export class AccredibleDialogTemplateComponent {
  private readonly _dialogRef = inject(DialogRef);
  private readonly _language = inject(AccredibleLanguageService);

  title = input<string>();
  subtitle = input<string>();
  centered = input(false);
  actionBtnText = input(this._language.translate('new-confirm-dialog.ok'));
  actionBtnColor = input<ButtonColor>('primary');
  actionBtnType = input<ButtonType>('solid');
  actionBtnDisabled = input(false);
  cancelBtnText = input(this._language.translate('new-confirm-dialog.cancel'));
  cancelBtnColor = input<ButtonColor>('natural');
  cancelBtnType = input<ButtonType>('solid');
  isLoading = input(false);
  closeBtn = input(true);

  // TODO(Fred): We are using `actionBtnClicked.observed` in the HTML but the output signal doesn't have this observed property,
  //  so we could either not rely on the observed property to show or not show buttons or
  //  we could wait for Angular to implement this as it is being discussed here https://github.com/angular/angular/issues/54837
  @Output()
  actionBtnClicked = new EventEmitter<boolean>();

  /**
   * @param cancelBtnBoolean :
   *  false - clicking cancel button
   *  undefined - clicking the backdrop or the dialog close button
   */
  close(cancelBtnBoolean?: boolean): void {
    this._dialogRef.close(cancelBtnBoolean);
  }

  submit(): void {
    if (!this.isLoading()) {
      this.actionBtnClicked.emit();
    }
  }
}
