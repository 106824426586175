<div *transloco="let t; read: 'credential-tile-content'"
  [class.ghost]="!credential()?.id">
  <div class="image-container">
    <div class="image">
      @if (credential().isCustomCredential) {
        <div class="custom-credential"
          role="img"
          [attr.aria-label]="t('aria_custom_credential', { courseName: credential().name })"
          [lazyLoad]="credential().imageUrl">
        </div>
      } @else {
        <div class="appearance-wrapper">
          <accredible-credential-appearance [group]="credential().credential?.group"
            [partialCredential]="credential().credential">
          </accredible-credential-appearance>
        </div>
      }
    </div>
  </div>

  <div class="details">
    <h2 class="mat-h3">
      {{ credential().name }}
    </h2>

    @if (credential().credential?.endorsements?.length) {
      <accredible-chip img="assets/images/colored-icons/endorsement.svg"
        [data]="credential().credential.endorsements.length > 1 ? t('endorsements', {count: credential().credential.endorsements.length}) : t('endorsed_by', {endorser: credential().credential.endorsements[0].endorser.name}) "/>
    }

    <div class="mat-body-2 issued-on">
      {{ credential().issuedOn | localeDate:'longDate':(languageCode$ | async) }}
    </div>

    <div class="issuer-name">
      {{ credential().issuerName }}
    </div>
  </div>
</div>
