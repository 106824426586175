<ng-template>
  <div class="tab-header"
    [class.tab-active]="active"
    [ngClass]="{'tab-active': active, 'disabled': disabled}"
    [attr.data-cy]="dataCy"
    (click)="!disabled && tabSelected.emit()">
    <div class="header-content">
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
