<div class="tile-gallery"
  cdkDropListGroup
  [cdkDropListGroupDisabled]="!isCredentialOwner || !enableReorder">
  <div class="tile"
    #placeholder
    cdkDropList
    (cdkDropListEntered)="onEnter($event)"
    (cdkDropListDropped)="onDrop()">
  </div>

  <div *ngFor="let item of items; trackBy: trackByFn"
    class="tile"
    [style.height]="fixedHeight"
    [class.ghost]="!item?.id"
    cdkDropList
    (cdkDropListEntered)="onEnter($event)"
    (cdkDropListDropped)="onDrop()">

    <div cdkDrag>
      <ng-template *ngTemplateOutlet="itemTemplateRef; context: { $implicit: item }">
      </ng-template>

      <div *ngIf="item && isCredentialOwner && enableReorder"
        class="dnd-handle"
        aria-hidden="true"
        cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
    </div>
  </div>

  <ng-content select="[externalTiles]"></ng-content>
</div>
