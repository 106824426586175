<label *ngIf="label"
  class="font-normal-semibold"
  [for]="id">
  {{ label }}
</label>

<div *ngIf="hint"
  class="hint font-s-regular">
  {{ hint }}
</div>

<div class="input-wrapper"
  [class.has-icon]="!!icon"
  [class.has-reset]="showReset && !!formControlRef.value?.length"
  [class.error]=formControlRef.invalid
  [ngClass]="iconPosition + '-icon'">
  <input #textInput
    class="font-normal-regular"
    [ngClass]="size"
    [class.has-icon]="!!icon"
    [type]="type"
    [id]="id"
    [placeholder]="placeholder"
    [readOnly]="readonly"
    [formControl]="formControlRef"
    [required]="required"
    [autocomplete]="autoComplete"
    [attr.maxLength]="type !== 'number' ? maxLength || null : null"
    [min]="min"
    [max]="max"
    [attr.data-cy]="label ? label.split(' ').join('-').toLowerCase() + '-input' : null"
    (blur)="onBlur($event)">

  <accredible-icon *ngIf="icon"
    class="input-icon"
    [class.clickable-icon]="isClickable"
    [icon]="icon"
    [attr.role]="isClickable ? 'button' : null"
    [attr.tabIndex]="isClickable ? '0' : '-1'"
    (keydown.enter)="isClickable ? iconClick.emit(true) : null"
    (keydown.space)="isClickable ? iconClick.emit(true) : null"
    (click)="isClickable ? iconClick.emit(true): null">
  </accredible-icon>

  <accredible-icon *ngIf="showReset && iconPosition !== 'end' && !!formControlRef.value?.length"
    role="button"
    tabindex="0"
    class="reset-icon clickable-icon"
    [icon]="'close-small'"
    (click)="formControlRef.reset('')">
  </accredible-icon>
</div>

<div *ngIf="!noError"
  class="feedback-message">
  <ng-container *ngIf="formControlRef.touched">
    <ng-content select="accredible-input-feedback"></ng-content>
  </ng-container>
</div>
