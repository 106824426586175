import { AccrediblePageMeta } from '@accredible-frontend-v2/models';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccrediblePaginationComponentModule } from '@accredible-frontend-v2/new-components/pagination';
import {
  AccredibleTableColumn,
  AccredibleTableColumnType,
  AccredibleTableModule,
} from '@accredible-frontend-v2/new-components/table';
import { AccredibleToastService } from '@accredible-frontend-v2/new-components/toast';
import { AccredibleLanguageService } from '@accredible-frontend-v2/services/language';
import { SelectionChange } from '@angular/cdk/collections';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { of, tap } from 'rxjs';
import { Evaluator, ListEvaluatorsParams } from '../../../../models/evaluator.model';
import { EvaluatorsService } from '../../../../services/evaluators.service';
import { DeleteSingleViewerButtonComponent } from './components/delete-single-viewer-button/delete-single-viewer-button.component';
import { DeleteViewerConfirmationComponent } from './components/delete-viewer-confirmation/delete-viewer-confirmation.component';

const TRANSLATION_KEY = 'list-viewers';

const GHOST_VIEWERS = Array.from({ length: 12 }, () => ({}));

enum TableColumnDef {
  EMAIL = 'email',
  STATUS = 'status',
  ACTIONS = 'actions',
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslocoDirective,
    AccredibleTableModule,
    AccrediblePaginationComponentModule,
    AccredibleButtonComponentModule,
    DeleteSingleViewerButtonComponent,
    DeleteViewerConfirmationComponent,
  ],
  selector: 'db-list-viewers',
  templateUrl: './list-viewers.component.html',
  styleUrls: ['./list-viewers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListViewersComponent implements OnInit {
  private readonly _language = inject(AccredibleLanguageService);
  private readonly _evaluators = inject(EvaluatorsService);
  private readonly _toast = inject(AccredibleToastService);

  spotlightDirectoryId = input<number>();
  selectedCredentials = signal<string[]>([]);
  showBulkActions = signal(false);
  confirmingDeletion = signal(false);

  viewers = signal<Evaluator[]>(GHOST_VIEWERS as Evaluator[]);
  pageMeta = signal<AccrediblePageMeta>({} as AccrediblePageMeta);
  params = signal<ListEvaluatorsParams>({
    page: 1,
    role: 'evaluator',
    page_size: 20,
  });

  columnDef = TableColumnDef;

  displayedColumns: AccredibleTableColumn[] = [
    {
      def: TableColumnDef.EMAIL,
      width: '300px',
      label: this._language.selectTranslate(`${TRANSLATION_KEY}.viewer_email`),
      type: AccredibleTableColumnType.CUSTOM_TEMPLATE,
    },
    {
      def: TableColumnDef.STATUS,
      width: '150px',
      label: this._language.selectTranslate(`${TRANSLATION_KEY}.status`),
      type: AccredibleTableColumnType.CUSTOM_TEMPLATE,
    },
    {
      def: TableColumnDef.ACTIONS,
      width: '15%',
      label: of(''),
      type: AccredibleTableColumnType.CUSTOM_TEMPLATE,
    },
  ];

  ngOnInit(): void {
    this._searchViewers();
  }

  onCredentialsSelectionChangeFromTable(selection: SelectionChange<string | number>): void {
    this.selectedCredentials.set(<string[]>selection.source.selected);
    this.showBulkActions.set(selection.source.selected.length > 0);
  }

  onPageChange(page: number): void {
    this.params.update((currentParams) => ({
      ...currentParams,
      page,
    }));
    this._searchViewers();
  }

  confirmBulkDeletion(): void {
    this._evaluators
      .deleteEvaluators(this.selectedCredentials(), this.spotlightDirectoryId())
      .subscribe({
        next: () => {
          this._searchViewers();
          this._handleDeletionResponse(this.selectedCredentials().length, true);
          this.selectedCredentials.set([]);
          this.confirmingDeletion.set(false);
          this.showBulkActions.set(false);
        },
        error: () => {
          this._handleDeletionResponse(this.selectedCredentials().length, false);
        },
      });
  }

  deleteSingleInvitation(id: string): void {
    this._evaluators.deleteEvaluators([id], this.spotlightDirectoryId()).subscribe({
      next: () => {
        this._searchViewers();
        this._handleDeletionResponse(1, true);
      },
      error: () => {
        this._handleDeletionResponse(1, false);
      },
    });
  }

  private _searchViewers(): void {
    this._evaluators
      .searchEvaluators(this.params(), this.spotlightDirectoryId())
      .pipe(
        tap((data) => {
          this.viewers.set(data.spotlight_directory_evaluator_profiles);
          this.pageMeta.set(data.meta);
        }),
      )
      .subscribe();
  }

  private _handleDeletionResponse(invitationCount: number, success: boolean): void {
    if (success) {
      this._toast.success(
        this._language.translate(`${TRANSLATION_KEY}.deletion_success`, {
          count: invitationCount,
        }),
      );
    } else {
      this._toast.error(
        this._language.translate(`${TRANSLATION_KEY}.deletion_error`, {
          count: invitationCount,
        }),
      );
    }
  }
}
