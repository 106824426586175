<div class="db-tabs"
  [class.tabs-hidden]="renderHiddenTabs">
  <div class="header-container"
    role="tablist"
    [attr.aria-label]="label">
    <div
      #headerTabs
      *ngFor="let tab of tabs; index as i"
      id="{{ tab.tabHeader.identifier }}"
      class="header-tab"
      role="tab"
      [attr.tabindex]="tab.tabHeader.active ? '0' : '-1'"
      [attr.aria-selected]="tab.tabHeader.active"
      [attr.aria-controls]="tab.tabContent.identifier"
      (click)="!tab.tabHeader.disabled && selectTab(i)"
      (keydown.home)="switchTab(0)"
      (keydown.end)="switchTab(tabs.length - 1)"
      (keydown.ArrowLeft)="switchTab(i - 1)"
      (keydown.ArrowUp)="switchTab(i - 1)"
      (keydown.ArrowRight)="switchTab(i + 1)"
      (keydown.ArrowDown)="switchTab(i + 1)">
      <ng-container *ngIf="tab.tabHeader">
        <ng-container [ngTemplateOutlet]="tab.tabHeader.header"></ng-container>
      </ng-container>
    </div>
  </div>

  <ng-container *ngFor="let tab of tabs">
    <div
      *ngIf="renderHiddenTabs || tab.tabHeader.active"
      id="{{ tab.tabContent.identifier }}"
      class="tabs-content"
      [class.tabs-content-active]="renderHiddenTabs && tab.tabHeader.active"
      role="tabpanel"
      [attr.tabindex]="tab.tabHeader.active ? '0' : '-1'"
      [attr.aria-labelledby]="tab.tabHeader.identifier">
      <ng-container [ngTemplateOutlet]="tab.tabContent.content"></ng-container>
    </div>
  </ng-container>
</div>
