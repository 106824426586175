<ng-container *transloco="let t; read: 'list-viewers'">
  @if (showBulkActions()) {
    <div class="bulk-actions">
      <div class="font-normal-regular">
        {{ t('selected_viewers', { count: selectedCredentials().length }) }}
      </div>

      @if (!confirmingDeletion()) {
        <button accredible-button-outline
          color="danger"
          (click)="confirmingDeletion.set(true)">
          {{ t('cancel_invitations', { count: selectedCredentials().length }) }}
        </button>
      } @else {
        <db-delete-viewer-confirmation [deletionCount]="selectedCredentials().length"
          (cancelDeletion)="confirmingDeletion.set(false)"
          (confirmDeletion)="confirmBulkDeletion()" />
      }
    </div>
  }

  <accredible-table [class.bulk-selection]="showBulkActions()"
    [dataSource]="viewers()"
    [columns]="displayedColumns"
    [allowMultiSelect]="true"
    [allowRowSelection]="true"
    [noBorder]="true"
    [emptyTableMessage]="t('no_results')"
    (selectionChange)="onCredentialsSelectionChangeFromTable($event)">
    <ng-template let-element
      let-column="column">
      @switch (column.def) {
        @case (columnDef.EMAIL) {
          <div class="email">
            {{ element.evaluator_profile?.user?.email }}
          </div>
        }
        @case (columnDef.STATUS) {
          <div class="status">
            {{ t('statuses.' + element.status) }}
          </div>
        }
        @case (columnDef.ACTIONS) {
          <db-delete-single-viewer-button [viewerId]="element.id"
            (deleteInvitation)="deleteSingleInvitation($event)" />
        }
      }
    </ng-template>
  </accredible-table>

  <div class="pagination-container">
    <accredible-pagination [pageName]="t('invited_viewers')"
      [pageMeta]="pageMeta()"
      (pageChange)="onPageChange($event)" />
  </div>
</ng-container>
