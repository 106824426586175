<ng-container *transloco="let t; read: 'directory-visibility-settings'">

  <accredible-radio-input [formControlRef]="formGroup.controls.visibility.controls.private"
    [label]="t('title')"
    [hint]="t('description')"
    [options]="options()"
    [optionContainer]="true"
    [noError]="true"
    [dataCy]="radioInputDataCy"/>

  @if (formGroup.controls.visibility.controls.private.value) {
    <ng-container *ngrxLet="evaluatorProfilesCount$ as evaluatorProfilesCount">
      <div class="viewers">
        <div class="title font-normal-semibold">{{ t('viewers.title') }}</div>

        <div class="invited">
          <div class="description">
            <accredible-icon icon="view"/>

            @if (evaluatorProfilesCount?.evaluators) {
              <span class="font-normal-semibold invited-count">
                {{ t('viewers.invited_viewers', { count: evaluatorProfilesCount?.evaluators }) }}
              </span>
            } @else {
              <span>{{ t('viewers.no_viewers') }}</span>
            }
          </div>

          <button accredible-link
            icon="arrow-right"
            data-cy="btn-invite-viewers"
            (click)="onInviteViewersOrTeamMembers(evaluatorType.VIEWER)">
            {{ t('viewers.invite_viewers') }}
          </button>
        </div>
      </div>

      <div class="team-members">
        <div class="title font-normal-semibold">
          {{ t('team_members.title') }}
          <accredible-icon icon="info"
            [accredibleTooltip]="t('team_members.info')"
            [tooltipMaxWidth]="600"/>
        </div>

        <div class="invited">
          <div class="description">
            <accredible-icon icon="user"/>

            @if (evaluatorProfilesCount?.owners) {
              <span class="font-normal-semibold invited-count">
                {{ t('team_members.invited_team_members', { count: evaluatorProfilesCount?.owners }) }}
              </span>
            } @else {
              <span>{{ t('team_members.no_team_members') }}</span>
            }
          </div>

          <button accredible-link
            icon="arrow-right"
            data-cy="btn-invite-team-members"
            (click)="onInviteViewersOrTeamMembers(evaluatorType.TEAM_MEMBER)">
            {{ t('team_members.invite_team_members') }}
          </button>
        </div>
      </div>
    </ng-container>
  }

</ng-container>
