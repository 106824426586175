import { CommonModule } from '@angular/common';
import { Component, ContentChild } from '@angular/core';
import { TabContentComponent } from '../tab-content/tab-content.component';
import { TabHeaderComponent } from '../tab-header/tab-header.component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'accredible-tab',
  template: ` <ng-content></ng-content>`,
})
export class TabComponent {
  @ContentChild(TabHeaderComponent)
  tabHeader: TabHeaderComponent;

  @ContentChild(TabContentComponent)
  tabContent: TabContentComponent;
}
