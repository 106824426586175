import { DashboardDepartment } from './db-department.model';
import { DashboardUser } from './db-user.model';

export enum CollectionsPlan {
  CONNECT = 'connect',
  GROWTH = 'growth',
}

export enum EmailCampaignsPlan {
  TRIAL = 'trial',
  CONNECT = 'connect',
  GROWTH = 'growth',
  LAUNCH = 'launch',
}

export enum DashboardRole {
  EDITOR = 'editor',
  VIEWER = 'viewer',
  CREDENTIAL_ISSUER = 'credential_issuer',
}

export interface DashboardIssuer {
  user: DashboardUser;
  department: DashboardDepartment;
  permissions: DashboardIssuerPermissions;
  stripe_customer_id: string;
  phone_number: number;
  agreed_to_recommendations: boolean;
}

export interface DepartmentSecuritySettings {
  mfa_enabled: boolean;
  meta?: {
    mfa_configured: number;
    mfa_not_configured: number;
    mfa_reset_requests: number;
  };
}

export interface DashboardIssuerPermissions {
  team: boolean;
  groups: boolean;
  view: boolean;
  //TODO(Manuel) remove string type in future refactor of roles and permissions
  role: string | DashboardRole;
  group_ids: any[];
  verified: boolean;
  directory: boolean;
  analytics: boolean;
  emails: boolean;
  credential_attributes: boolean;
  designs: boolean;
  api: boolean;
  settings: boolean;
  created_org: boolean;
  spotlight_directory: boolean;
  admin: boolean;
  switch_department: boolean;
}
