import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { SidenavContainer } from './sidenav-container';
import { AccredibleSidenavService } from './sidenav.service';

@NgModule({
  imports: [PortalModule, OverlayModule],
  exports: [],
  declarations: [SidenavContainer],
  providers: [AccredibleSidenavService],
})
export class AccredibleSidenavServiceModule {}
