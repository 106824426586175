import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { AccredibleSyncOrAsyncPipeModule } from '@accredible-frontend-v2/pipes/sync-or-async';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoDirective } from '@jsverse/transloco';
import { AccredibleRadioInputComponent } from './radio-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    TranslocoDirective,
    AccredibleSyncOrAsyncPipeModule,
    AccredibleIconComponentModule,
    AccredibleTooltipModule,
  ],
  declarations: [AccredibleRadioInputComponent],
  exports: [AccredibleRadioInputComponent],
})
export class AccredibleRadioInputComponentModule {}
