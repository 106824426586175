import { AccredibleMfaSettings } from '@accredible-frontend-v2/models';

export interface DashboardUser {
  id: number;
  name: string;
  email: string;
  verified: boolean;
  mfa_settings: AccredibleMfaSettings;
  first_time_user_experience: DashboardFirstTimeUserExperience;
}

interface DashboardFirstTimeUserExperience {
  dpaUpdateAccepted: boolean;
  collections: boolean;
  'analytics-disclaimer': boolean;
  welcomeToAccredible: boolean;
}
