import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';
import { addErrors, removeErrors } from './validator.helper';

export const atLeastOneRequiredValidator = (controls: string[]) => {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    const clearControlErrors = (): void => {
      controls.forEach((control) => removeErrors(formGroup.get(control), ['required']));
    };

    for (const control of controls) {
      if (!isEmpty(formGroup.get(control).value)) {
        clearControlErrors();
        return null;
      }

      addErrors(formGroup.get(control), { required: true });
    }

    return { atLeastOneRequired: true };
  };
};
