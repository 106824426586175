<ng-container *transloco="let t; read: 'list-team-members'">
  <accredible-table [dataSource]="teamMembers()"
    [columns]="displayedColumns"
    [noBorder]="true"
    [emptyTableMessage]="t('no_results')">
    <ng-template let-element
      let-column="column">
      @switch (column.def) {
        @case (columnDef.ROLE) {
          <div>{{ t('roles.' + element.role) }}</div>
        }
        @case (columnDef.ACTIONS) {
          <div class="action-buttons">
            @if (element.status === invitationStatus.NOT_INVITED) {
              <button accredible-button-outline
                size="small"
                [isLoading]="isInviting() === element.id"
                [hideText]="isInviting() === element.id"
                (click)="onInvite(element)">
                {{ t('statuses.invite') }}
              </button>
            } @else {
              <button accredible-button-outline
                size="small"
                icon="chevron-down"
                [color]="element.status === invitationStatus.INVITATION_SENT ? 'natural' : 'success'"
                [cdkMenuTriggerFor]="actionsMenu">
                {{ element.status === invitationStatus.INVITATION_SENT ? t('statuses.invite_sent'): t('statuses.invited') }}
              </button>

              <ng-template #actionsMenu>
                <div cdkMenu>
                  @if (element.status === invitationStatus.INVITATION_SENT) {
                    <button cdkMenuItem
                      (click)="onResendInvitation(element)">
                      {{ t('resend_invitation') }}
                    </button>
                  }

                  <button cdkMenuItem
                    class="danger"
                    (click)="onCancelInvitation(element)">
                    {{ t('cancel_invitation') }}
                  </button>
                </div>
              </ng-template>
            }
          </div>
        }
      }
    </ng-template>
  </accredible-table>

  <div class="pagination-container">
    <accredible-pagination [pageName]="t('team_members')"
      [pageMeta]="pageMeta()"
      (pageChange)="onPageChange($event)"/>
  </div>
</ng-container>
