import { CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import { Component, ComponentRef, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { sidenavContainerAnimation } from './sidenav.animations';

@Component({
  templateUrl: 'sidenav-container.html',
  styleUrls: ['sidenav-container.scss'],
  animations: [sidenavContainerAnimation],
  host: {
    '[@sidenavContainerAnimation]': `getAnimationState()`,
    '(@sidenavContainerAnimation.start)': 'onAnimationStart()',
  },
})
export class SidenavContainer {
  @ViewChild(CdkPortalOutlet, { static: true })
  portalOutlet: CdkPortalOutlet;

  private _state: 'opened' | 'closed' = 'closed';
  private _isClosing = false;

  attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
    return this.portalOutlet.attachComponentPortal(portal);
  }

  getAnimationState(): 'opened' | 'closed' {
    return this._state;
  }

  onAnimationStart(): void {
    if (!this._isClosing) {
      this._state = 'opened';
    }
  }

  /**
   * this._isClosing is set to true in order to not change the _state to 'opened' again onAnimationStart,
   * then we need return an Observable with a delay in order to wait for the animation to end.
   * This is used by the SideNavRef when closing the sidenav.
   * Didn't wanted to over engineer this feature.
   */
  setClosedStated(): Observable<unknown> {
    this._isClosing = true;
    this._state = 'closed';
    return of({}).pipe(delay(300));
  }
}
