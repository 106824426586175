import { AccredibleKeyboardFocusService } from '@accredible-frontend-v2/services/keyboard-focus';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

const TYPE_DEFAULT_VALUE = 'text';
const AUTOCOMPLETE_DEFAULT_VALUE = 'off';

let nextUniqueId = 0;

type IconPosition = 'start' | 'end';

@Component({
  selector: 'accredible-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  // We are not using ChangeDetectionStrategy.OnPush because this input component
  // does not detect changes when formGroup.markAllAsTouched() is triggered
})
export class AccredibleTextInputComponent implements AfterViewInit, OnDestroy {
  @Input()
  formControlRef: UntypedFormControl;
  @Input()
  label: string;
  @Input()
  hint: string;
  @Input()
  id = `input-text-${nextUniqueId++}`;
  @Input()
  placeholder = '';
  @Input()
  required = false;
  @Input()
  type: 'text' | 'number' | 'email' | 'password' = TYPE_DEFAULT_VALUE;
  @Input()
  size: 'small' | 'normal' | 'large' = 'normal';
  @Input()
  maxLength: number;
  @Input()
  autoComplete = AUTOCOMPLETE_DEFAULT_VALUE;
  @Input()
  readonly = false;
  @Input()
  icon: string;
  @Input()
  iconPosition: IconPosition = 'end';
  @Input()
  noError = false;
  @Input()
  showSuccess = false;
  @Input()
  showReset = false;
  @Input()
  min: number;
  @Input()
  max: number;

  @Output()
  iconClick = new EventEmitter<boolean>();
  @Output()
  blurEvent = new EventEmitter<Event>();

  @ViewChild('textInput')
  textInputEl: ElementRef<HTMLElement>;

  constructor(private readonly _keyboardFocus: AccredibleKeyboardFocusService) {}

  get isClickable(): boolean {
    return this.iconClick.observed;
  }

  onBlur(event: Event): void {
    this.blurEvent.emit(event);
  }

  ngAfterViewInit(): void {
    if (this.textInputEl) {
      this._keyboardFocus.startFocusMonitoring(this.textInputEl);
    }
  }

  ngOnDestroy(): void {
    if (this.textInputEl) {
      this._keyboardFocus.stopFocusMonitoring(this.textInputEl);
    }
  }
}
