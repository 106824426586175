<div class="label-tooltip-container">
  @if (label()) {
    <label class="font-normal-semibold"
      [id]="radioGroupLabelId"
      [class.has-error]="formControlRef().invalid && formControlRef().touched">
      {{ label() }}
    </label>
  }

  @if (tooltip()) {
    <accredible-icon [icon]="'info'"
      [accredibleTooltip]="tooltip()"
      [richTooltipTitle]="tooltipTitle()">
    </accredible-icon>
  }
</div>

@if (hint()) {
  <div class="hint font-multiline-s-regular">
    {{ hint() }}
  </div>
}

<div *transloco="let t; read: 'radio-input'"
  class="radio-input-group {{ direction() }}"
  role="radiogroup"
  [class.with-container]="optionContainer()"
  [attr.aria-label]="label()"
  [attr.aria-labelledby]="radioGroupLabelId">

  @if (optionContainer()) {
    <div class="container">
      <ng-container *ngTemplateOutlet="radioOptions"/>
    </div>
  } @else {
    <ng-container *ngTemplateOutlet="radioOptions"/>
  }

  <ng-template #radioOptions>
    @for (option of options(); track option; let i = $index) {
      <div [class.selected]="option.value === formControlRef().getRawValue()"
        [class.sameWidth]="sameWidthForAllOptions()"
        [style.max-width]="containerMaxWidth()">
        <label [class.disabled]="formControlRef().disabled"
          [class.has-image]="option.image"
          [class.has-preview]="option.preview"
          [accredibleTooltip]="option.premiumFeature ? option.premiumFeature.tooltipText || t('premium_feature_text') : null"
          [richTooltipTitle]="t('premium_feature')"
          [richTooltipHref]="option.premiumFeature?.tooltipLink"
          [richTooltipLinkText]="t('learn_more')"
          [tooltipDirection]="tooltipDirection.TOP_LEFT">
          <input type="radio"
            [name]="radioGroupLabelId"
            [attr.data-cy]="dataCy() || (optionId + '-' + i)"
            [attr.disabled]="option.disabled || null"
            [formControl]="formControlRef()"
            [value]="option.value"
            [attr.aria-label]="option.label"
            (change)="valueChange.emit(formControlRef().value)"/>

          <span class="input-circle">
            <span class="inner-input-circle"></span>
          </span>

          @if (option.image) {
            <div class="image">
              <img [ngSrc]="option.image"
                alt=""
                width="60"
                height="60"/>
            </div>
          }

          <div class="input-text font-normal-regular">
            <div>
              <div class="input-label">
                <span>{{ option.label | syncOrAsync }}</span>

                @if (option.premiumFeature) {
                  <accredible-icon class="premium-feature-icon"
                    icon="star"/>
                }
              </div>

              @if (!!option.extendedDescription) {
                <div class="description font-s-regular">
                  {{ option.extendedDescription | syncOrAsync }}
                </div>
              }
            </div>

            @if (option.hint) {
              <div class="radio-hint">
                {{ option.hint }}
              </div>
            }
          </div>

          @if (option.preview) {
            <div class="break"></div>

            @if (option.showPreviewOnSelection ? (option.value === formControlRef().getRawValue()) : true) {
              <div class="option-preview"
                aria-hidden="true">
                <ng-container *ngTemplateOutlet="option.preview"/>
              </div>
            }
          }
        </label>
      </div>
    }
  </ng-template>
</div>

@if (!noError()) {
  <div class="input-errors-container">
    @if (formControlRef().invalid && formControlRef().touched) {
      <ng-content select="[inputErrors]"/>
    }
  </div>
}
