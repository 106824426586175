export enum ProductOfferingKind {
  BASE = 'BASE',
  ADDON = 'ADD-ON',
}

export enum BillingPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface ProductOfferingPrice {
  id: string;
  stripe_price_id: string;
  name: string;
  billing_period: BillingPeriod;
  amount: number;
}

export interface ProductOffering {
  id: string;
  stripe_product_id: string;
  name: string;
  description: string;
  kind: ProductOfferingKind;
  prices: ProductOfferingPrice[];
}

export interface LocalProductOfferingPrice extends ProductOfferingPrice {
  isYearly: boolean;
}
