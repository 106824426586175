import { UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { addErrors, removeErrors } from './validator.helper';

export const pairedControlsValidator = (controlPairs: string[][]) => {
  return (formGroup: UntypedFormGroup): ValidationErrors | null => {
    let hasError = false;

    for (const pair of controlPairs) {
      const control1 = formGroup.get(pair[0]);
      const control2 = formGroup.get(pair[1]);

      if (Boolean(control1.value) === Boolean(control2.value)) {
        removeErrors(control1, ['invalidPair']);
        removeErrors(control2, ['invalidPair']);
      } else {
        addErrors(control1, { invalidPair: true });
        addErrors(control2, { invalidPair: true });
        hasError = true;
      }
    }

    return hasError ? { invalidPair: true } : null;
  };
};
