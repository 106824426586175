<div class="dialog-panel"
  role="dialog"
  aria-modal="true"
  tabindex="0"
  (keydown.escape)="close()">

  <div class="dialog-header"
    [class.has-title]="title()">
    @if (title()) {
      <h1 class="font-normal-semibold">
        {{ title() }}
      </h1>
    }

    @if (closeBtn()) {
      <accredible-icon role="button"
        tabindex="0"
        [icon]="'close'"
        (keydown.enter)="close()"
        (click)="close()">
      </accredible-icon>
    }
  </div>

  @if (subtitle()) {
    <div class="dialog-subheader">
      <h2 class="font-multiline-s-regular">{{ subtitle() }}</h2>
    </div>
  }

  @if (title()) {
    <div class="dialog-header-divider"></div>
  }

  <div class="dialog-content">
    <ng-content/>
  </div>

  @if (actionBtnClicked.observed) {
    <div class="dialog-actions"
      [class.centered]="centered()">
      @if (cancelBtnText()) {
        @if (cancelBtnType() === 'outline') {
          <button accredible-button-outline
            data-cy="btn-dialog-cancel"
            type="button"
            [color]="cancelBtnColor()"
            (click)="close(false)">
            {{ cancelBtnText() }}
          </button>
        } @else {
          <button accredible-button-flat
            data-cy="btn-dialog-cancel"
            type="button"
            [color]="cancelBtnColor()"
            (click)="close(false)">
            {{ cancelBtnText() }}
          </button>
        }
      }

      @if (actionBtnType() === 'outline') {
        <button accredible-button-outline
          data-cy="btn-dialog-action"
          [disabled]="actionBtnDisabled() || isLoading()"
          [isLoading]="isLoading()"
          [color]="actionBtnColor()"
          (click)="submit()">
          {{ actionBtnText() }}
        </button>
      } @else {
        <button accredible-button
          data-cy="btn-dialog-action"
          [disabled]="actionBtnDisabled() || isLoading()"
          [isLoading]="isLoading()"
          [color]="actionBtnColor()"
          (click)="submit()">
          {{ actionBtnText() }}
        </button>
      }
    </div>
  }
</div>
