import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { AccredibleLocaleDatePipeModule } from '@accredible-frontend-v2/pipes/locale-date';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatSortModule } from '@angular/material/sort';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { AccredibleTableComponent } from './table.component';

const MATERIAL_MODULES = [MatTableModule, MatSortModule, MatCheckboxModule];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AccredibleLocaleDatePipeModule,
    AccredibleIconComponentModule,
    AccredibleTooltipModule,
    TranslocoModule,
    ...MATERIAL_MODULES,
  ],
  exports: [AccredibleTableComponent],
  declarations: [AccredibleTableComponent],
  providers: [],
})
export class AccredibleTableModule {}
