import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@jsverse/transloco';
import { AccrediblePaginationComponent } from './pagination.component';

@NgModule({
  imports: [CommonModule, AccredibleButtonComponentModule, TranslocoModule],
  exports: [AccrediblePaginationComponent],
  declarations: [AccrediblePaginationComponent],
})
export class AccrediblePaginationComponentModule {}
