import { FeatureFlags } from '@accredible-frontend-v2/models';
import { Injectable, signal } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DashboardIssuer } from '../entities/db-issuer.model';
import { ProductOffering } from '../entities/db-product-offering.model';
import { DashboardApiService } from './db-api.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardIssuerService extends DashboardApiService {
  readonly dashboardIssuer = signal<DashboardIssuer>(null);
  readonly featureFlags = signal<FeatureFlags>(null);

  loadIssuer(): Observable<DashboardIssuer> {
    if (this.dashboardIssuer()) {
      return of(this.dashboardIssuer());
    }

    const url = `/v1/organizations/${this.embedding.departmentId}/details`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'issuer')),
      tap((issuer) => this.dashboardIssuer.set(issuer)),
      catchError((res) => this._handleError(res)),
    );
  }

  loadFeatureFlags(): Observable<FeatureFlags> {
    if (this.featureFlags()) {
      return of(this.featureFlags());
    }

    const url = `/v1/issuer/organizations/${this.embedding.departmentId}/features`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'features')),
      tap((featureFlags) => this.featureFlags.set(featureFlags)),
      catchError((res) => this._handleError(res)),
    );
  }

  loadProductOfferings(): Observable<ProductOffering[]> {
    const url = `/v1/issuer/product_offerings`;

    return this._get(url).pipe(
      map((res) => this._handleResponse(res, 'product_offerings')),
      catchError((res) => this._handleError(res)),
    );
  }
}
