<accredible-form-section-component *transloco="let t; read: 'visibility-settings'"
  [title]="privateSpotlightFF() ? t('new_title') : t('title')"
  [description]="privateSpotlightFF() ? t('new_description') : t('description')">

  @if (privateSpotlightFF()) {
    <db-directory-visibility-settings (saveChanges)="saveChanges.emit()"/>

    <accredible-divider/>
  }

  <db-profile-visibility-settings 
    [privateSpotlightFF]="privateSpotlightFF()" 
    [spotlightProfileRedesignFF]="spotlightProfileRedesignFF()" />

</accredible-form-section-component>
