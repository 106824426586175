import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';
import { SidenavContainer } from './sidenav-container';

/**
 * Reference to a sidenav opened via the AccredibleSidenavService
 */
export class SidenavRef {
  overlayRef = this._overlayRef;
  private readonly _afterClosed$ = new Subject();

  constructor(
    private readonly _overlayRef: OverlayRef,
    private readonly _containerInstance: SidenavContainer,
  ) {}

  /**
   * Closes the sidenav and emits afterClosed event
   * if data is passed on close, data will be provided on the afterClosed event
   */
  close(data?: unknown): void {
    this._containerInstance.setClosedStated().subscribe(() => {
      // Wait for the sidenav animation to end then dispose
      this._overlayRef.dispose();
    });
    this._afterClosed$.next(data);
    this._afterClosed$.complete();
  }

  // Returns an observable after sidenav is closed
  afterClosed(): Observable<unknown> {
    return this._afterClosed$.asObservable();
  }
}
