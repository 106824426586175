<a id="trkFeaturedReadLink"
  [href]="url()"
  target="_blank"
  [attr.aria-label]="ariaLabel()">
  <div class="featured-read-image"></div>

  <div class="bottom">
    <div class="font-s-semibold">{{ subtitle() }}</div>
    <h2 class="font-l-semibold">{{ title() }}</h2>

    <a accredible-link
      id="trkFeaturedReadLinkIn"
      [href]="url()"
      target="_blank"
      [color]="'light'">
      {{ urlLabel() }}
    </a>
  </div>
</a>
