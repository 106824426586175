<accredible-dialog-template [centered]="true"
  [actionBtnText]="actionBtnText()"
  [actionBtnColor]="actionBtnColor()"
  [actionBtnType]="actionBtnType()"
  [actionBtnDisabled]="actionBtnDisabled() || (checkboxText() ? !checkboxFormControl?.value : false)"
  [cancelBtnText]="cancelBtnText()"
  [cancelBtnColor]="cancelBtnColor()"
  [cancelBtnType]="cancelBtnType()"
  [isLoading]="isLoading()"
  (actionBtnClicked)="confirm()">
  <div class="confirm-dialog">
    @if (confirmDialogImage()) {
      <img [src]="confirmDialogImage()"
        alt="">
    }

    <h1 class="font-l-semibold">
      {{ confirmDialogTitle() }}
    </h1>

    <div class="confirm-dialog-content">
      @if (confirmDialogText()) {
        <div [innerHTML]="confirmDialogText()"></div>
      }

      @if (checkboxText()) {
        <div class="checkbox-wrapper">
          <accredible-checkbox-input data-cy="input-confirm-dialog-checkbox"
            [formControlRef]="checkboxFormControl"
            [label]="checkboxText()"
            [showFullText]="true"/>
        </div>
      }

      <ng-content/>
    </div>
  </div>
</accredible-dialog-template>
