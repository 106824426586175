<ng-container *transloco="let t; read: 'profile-visibility-settings'">
  @if (privateSpotlightFF()) {
    <legend class="font-normal-semibold">
      {{ t('title') }}
    </legend>
    <div class="description font-s-regular">
      {{ t('description') }}
    </div>
  }

  <db-hint type="info"
    icon="info1"
    [content]="t('info_message')"/>

  <accredible-radio-input [formControlRef]="formGroup.controls.visibility.controls.profileVisibility.controls.usersVisibleByDefault"
    [label]="t('form.users_visible_by_default.label')"
    [options]="usersVisibleByDefaultOptions"/>

  <accredible-radio-input [formControlRef]="formGroup.controls.visibility.controls.profileVisibility.controls.credentialVisibility"
    [label]="t('form.credential_visibility.label')"
    [options]="credentialVisibilityOptions"
    [direction]="'vertical'"/>

  <accredible-radio-input [formControlRef]="formGroup.controls.visibility.controls.profileVisibility.controls.includeExpiredCredentials"
    [label]="t('form.include_expired_credentials.label')"
    [options]="expiredOptions"
    [noError]="!spotlightProfileRedesignFF()"/>

  @if (spotlightProfileRedesignFF()) {
    <accredible-radio-input [formControlRef]="formGroup.controls.visibility.controls.profileVisibility.controls.additionalLinks"
      class="additional-links"
      [label]="t('form.additional_links.label')"
      [options]="additionalLinksOptions"
      [tooltip]="t('form.additional_links.tooltip')"
      [noError]="true"/>
  }
</ng-container>
