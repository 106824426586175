<ng-container *transloco="let t; read:'earning-criteria'">
  @if (earningCriteriaRequired?.length || earningCriteriaOptional?.length) {
    <div class="earning-criteria-container">
      <h2 class="mat-h3">{{ t('title') }}</h2>

      @if (earningCriteriaRequired.length) {
        <h3 class="mat-h4">{{ t('required') }}</h3>
        <ng-template *ngTemplateOutlet="criteriaTemplate; context: { $implicit: earningCriteriaRequired}"></ng-template>
      }

      @if (earningCriteriaOptional.length) {
        <h3 class="mat-h4">{{ t('optional') }}</h3>
        <ng-template *ngTemplateOutlet="criteriaTemplate; context: { $implicit: earningCriteriaOptional}"></ng-template>
      }

    </div>
  }

  <ng-template #criteriaTemplate
    let-criteria>
    <div class="criteria-list-container">
      @for (criteriaItem of criteria; track $index) {
        <div class="criteria-row">
          <accredible-chip [icon]="getEarningCriteriaIcon(criteriaItem)"
            [data]="t('kind.'+ criteriaItem.kind)"/>

          @if (criteriaItem.url) {
            <a [href]="criteriaItem.url"
              target="_blank"
              [innerHTML]="criteriaItem.text">
            </a>
          } @else {
            <div class="accredible-rich-text-wrapper"
              [innerHTML]="criteriaItem.text"></div>
          }
        </div>
      }
    </div>
  </ng-template>
</ng-container>
