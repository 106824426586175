import { WalletCredential } from '@accredible-frontend-v2/models';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { AccredibleTooltipModule } from '@accredible-frontend-v2/new-components/tooltip';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  forwardRef,
  input,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { CredentialTileContentComponent } from '../credential-tile-content/credential-tile-content.component';
import { CredentialTileStatusComponent } from '../credential-tile-status/credential-tile-status.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    CredentialTileContentComponent,
    AccredibleIconComponentModule,
    CredentialTileStatusComponent,
    AccredibleTooltipModule,
    TranslocoModule,
  ],
  selector: 'accredible-credential-selection-tile',
  templateUrl: './credential-selection-tile.component.html',
  styleUrls: ['./credential-selection-tile.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CredentialSelectionTileComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CredentialSelectionTileComponent implements OnInit, ControlValueAccessor {
  credential = input<WalletCredential>();
  disableSelection = input<boolean>();

  selectionChange = output<boolean>();

  private _isSelected = signal<boolean>(false);
  private _isDisabled = signal<boolean>(false);
  isDisabled = computed(() => this.disableSelection() || this._isDisabled());
  isSelected = computed(() => this.credential().selected || this._isSelected());

  ngOnInit(): void {
    if (this.credential().selected) {
      this._isSelected.set(true);
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(value: boolean): void {
    this._isSelected.set(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._isDisabled.set(isDisabled);
  }

  toggleSelection(): void {
    if (this.isDisabled()) {
      return;
    }

    this._isSelected.set(!this._isSelected());
    this.onChange(this._isSelected());
    this.onTouched();
    this.selectionChange.emit(this._isSelected());
  }
}
