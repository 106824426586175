import { UntypedFormGroup, ValidatorFn } from '@angular/forms';

// Custom validator to check that two fields match
export const mustMatchValidator = (controlName: string, matchingControlName: string) => {
  return (formGroup: UntypedFormGroup): ValidatorFn => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (control.value && matchingControl.value) {
      control.value !== matchingControl.value
        ? matchingControl.setErrors({ mustMatch: true })
        : matchingControl.setErrors(null);
    }

    return null;
  };
};
