import { ChangeDetectionStrategy, Component, input, output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { DeleteViewerConfirmationComponent } from '../delete-viewer-confirmation/delete-viewer-confirmation.component';

@Component({
  standalone: true,
  selector: 'db-delete-single-viewer-button',
  templateUrl: './delete-single-viewer-button.component.html',
  styleUrls: ['./delete-single-viewer-button.component.scss'],
  imports: [
    CommonModule,
    AccredibleButtonComponentModule,
    AccredibleIconComponentModule,
    DeleteViewerConfirmationComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteSingleViewerButtonComponent {
  viewerId = input<string>();
  deleteInvitation = output<string>();

  confirmingDeletion = signal(false);

  toggleRowDelete(event?: Event): void {
    // stopPropagation needed to prevent row selection
    event?.stopPropagation();
    this.confirmingDeletion.set(!this.confirmingDeletion());
  }

  delete(): void {
    this.deleteInvitation.emit(this.viewerId());
  }
}
