import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidCredentialUuidOrID } from '../../utils/src/lib/credential-id.helper';

export const credentialIdValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      return !isValidCredentialUuidOrID(value) ? { credentialId: true } : null;
    }

    return null;
  };
};
