export interface TeamMember {
  id: number;
  issuer_login_id: number;
  organization_id?: number;
  issuer_login: TeamMemberIssuer;

  admin: boolean;
  designs: boolean;
  emails: boolean;
  credential_attributes: boolean;
  analytics: boolean;

  settings: boolean;
  // directory and employment are DEPRECATED permissions,
  // if settings is true, directory and employment needs to be sent as true as well to the BE,
  // if settings is false, both should be sent as false.
  directory: boolean;
  employment: boolean;

  team: boolean;
  api: boolean;
  role: 'viewer' | 'editor';

  // If groups_permitted is true, team member has access to 'All groups',
  groups: TeamMemberGroup[];
  all_groups_permitted: boolean;
  // TODO(Joao) To be removed once the feature flag is not needed
  groups_permitted: TeamMemberGroup[] | true;
  group_collections: { id: string; name: string }[];
  spotlight_directory: boolean;

  verified: boolean;
  blocked: boolean;
  created_org: boolean;
}

export interface TeamMemberIssuer {
  id: number;
  name: string;
  email: string;
  last_login?: string;
  mfa_settings: TeamMemberMFASettings;
}

export interface TeamMemberMFASettings {
  id: number;
  configured: boolean;
  reset_code?: string;
}

export interface TeamMemberGroup {
  id: number;
  name: string;
}
