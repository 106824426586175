import { AbstractControl, ValidationErrors } from '@angular/forms';

export const removeErrors = (control: AbstractControl, keys: string[]): void => {
  if (!control || !keys || keys.length === 0) {
    return;
  }

  const remainingErrors = Object.entries(control.errors || <ValidationErrors>{}).reduce(
    (acc, [key, value]) => {
      if (!keys.includes(key)) {
        acc[key] = value;
      }
      return acc;
    },
    <ValidationErrors>{},
  );

  control.setErrors(Object.keys(remainingErrors).length > 0 ? remainingErrors : null);
};

export const addErrors = (
  control: AbstractControl,
  errors: { [key: string]: any },
  markAsTouched?: boolean,
): void => {
  if (!control || !errors) {
    return;
  }

  control.setErrors({ ...control.errors, ...errors });
  markAsTouched && control.markAsTouched();
};
