<div *transloco="let t; read: 'invite-viewers'">
  <form class="form-container"
    [formGroup]="formGroup"
    (ngSubmit)="submit()">
    <div class="input-wrapper">
      <label class="label-container font-normal-semibold">
        {{ t('invite_viewers') }}
        <accredible-icon [icon]="'info'"
          [accredibleTooltip]="t('tooltip')" />
      </label>

      <accredible-text-input data-cy="input-emails-list"
        [formControlRef]="formGroup.controls.emails"
        [hint]="t('hint')"
        [placeholder]="t('placeholder')">
        @if (formGroup.controls.emails.hasError('required')) {
          <accredible-input-feedback type="error"
            [message]="t('form_errors.required')" />
        }

        @if (formGroup.controls.emails.hasError('maxEmails')) {
          <accredible-input-feedback type="error"
            [message]="t('form_errors.max_emails')" />
        }

        @if (formGroup.controls.emails.hasError('invalidEmails')) {
          <accredible-input-feedback type="error"
            [message]="t('form_errors.invalid_emails')" />
        }

        @if (formGroup.controls.emails.hasError('missingCommas')) {
          <accredible-input-feedback type="error"
            [message]="t('form_errors.missing_commas')" />
        }

        @if (formGroup.controls.emails.hasError('duplicateEmails')) {
          <accredible-input-feedback type="error"
            [message]="t('form_errors.duplicate_emails')" />
        }
      </accredible-text-input>
    </div>

    <div class="submit-container">
      <button accredible-button
        data-cy="btn-submit-invite-viewers"
        [isLoading]="isLoading()">
        {{ t('submit') }}
      </button>
    </div>
  </form>
</div>
