import { AccrediblePageData } from '@accredible-frontend-v2/models';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TeamMember } from '../entities/team-members.model';
import { DashboardApiService } from './db-api.service';

export interface TeamMemberSearchParams {
  page: number;
  page_size: number;
  entity: 'corporation' | 'organization';
  distinct_issuer_logins?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class TeamMembersService extends DashboardApiService {
  loadTeamMembers(params: TeamMemberSearchParams): Observable<AccrediblePageData<TeamMember>> {
    const url = `/v1/issuer/organizations/${this.embedding.departmentId}/organization_permissions/search`;

    return this._post(url, params).pipe(
      map((res) => this._handleResponse(res)),
      catchError((res) => this._handleError(res)),
    );
  }
}
