import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

// Custom validator that checks if start date is before end date
export const startEndDateValidator = (startDateControlName: string, endDateControlName: string) => {
  return (formGroup: UntypedFormGroup): ValidationErrors => {
    const startDateControl = formGroup.controls[startDateControlName];
    const endDateControl = formGroup.controls[endDateControlName];

    if (startDateControl.value && endDateControl.value) {
      new Date(startDateControl.value) > new Date(endDateControl.value)
        ? endDateControl.setErrors({ beforeStartDate: true })
        : endDateControl.setErrors(null);
    }

    return null;
  };
};
