import { AccredibleButtonComponentModule } from '@accredible-frontend-v2/new-components/button';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { SIDENAV_DATA, SidenavRef } from '@accredible-frontend-v2/services/sidenav';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoDirective } from '@jsverse/transloco';
import { ListTeamMembersComponent } from './components/list-team-members/list-team-members.component';

@Component({
  standalone: true,
  imports: [
    TranslocoDirective,
    AccredibleIconComponentModule,
    AccredibleButtonComponentModule,
    ListTeamMembersComponent,
  ],
  templateUrl: './team-members-sidenav.component.html',
  styleUrls: ['./team-members-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeamMembersSidenavComponent {
  private readonly _sidenavRef = inject(SidenavRef);
  readonly data = inject<{ id: number }>(SIDENAV_DATA);

  close(): void {
    this._sidenavRef.close();
  }
}
