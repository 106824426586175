<ng-container *ngIf="dataSource?.length > 0; else emptyTemplate">
  <table *transloco="let t; read: 'accredible-table'"
    class="mat-elevation-z2"
    mat-table
    matSort
    [matSortActive]="sortActive"
    [matSortDirection]="sortDirection"
    [class.no-border]="noBorder"
    [ngClass]="{'no-border': noBorder, 'selection-disabled': selectionDisabled}"
    [dataSource]="dataSource"
    [fixedLayout]="true"
    [trackBy]="trackById"
    [attr.aria-label]="ariaLabel"
    (matSortChange)="onSortChange($event)">

    <!-- Select Column -->
    <ng-container matColumnDef="select">
      <th *matHeaderCellDef
        class="select"
        mat-header-cell>
        <mat-checkbox [checked]="selection?.hasValue() && isAllSelected()"
          [disabled]="selectionDisabled"
          [indeterminate]="selection?.hasValue() && !isAllSelected()"
          (change)="$event ? masterToggle() : null">
        </mat-checkbox>
      </th>

      <td *matCellDef="let element"
        mat-cell
        class="select">
        <ng-container *ngIf="element.id; else ghostTemplate">
          <mat-checkbox [checked]="selection?.isSelected(element.id)"
            [disabled]="selectionDisabled || (allowMultiSelectValidation && element.disabled)"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element.id) : null">
          </mat-checkbox>
        </ng-container>
      </td>
    </ng-container>

    <!-- Other Columns -->
    <ng-container *ngFor="let column of columns"
      [matColumnDef]="column.def">
      <th *matHeaderCellDef
        mat-header-cell
        [style.width]="column.width"
        [disabled]="!column.sort"
        [mat-sort-header]="column.def">
        {{ column.label | async }}
        <accredible-icon *ngIf="column.toolTip"
          [icon]="'info'"
          [accredibleTooltip]="column.toolTip | async">
        </accredible-icon>
      </th>

      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="tableColumnType.STRING">
          <td *matCellDef="let element"
            mat-cell
            class="column-type-string"
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              {{ element[column.def] }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.DATE">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              {{ element[column.def] | localeDate:'mediumDate':(languageCode$ | async) }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.MONTH_DATE">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              {{ element[column.def] | localeDate:'MMMM, YYYY':(languageCode$ | async) }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.CURRENCY">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              {{ element[column.def] | currency:element[column.extraDef]:'symbol' }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.ROUTER_LINK">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              <a [routerLink]="element[column.extraDef]">
                {{ element[column.def] }}
              </a>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.EXTERNAL_LINK">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              <a *ngIf="element[column.def]"
                [href]="element[column.extraDef]"
                [attr.aria-label]="t('aria_external_link', { url: element[column.def] })"
                target="_blank">
                {{ element[column.def] }}
              </a>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchCase="tableColumnType.CUSTOM_TEMPLATE">
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              <ng-template *ngTemplateOutlet="customCellTemplateRef; context: { $implicit: element, column: column }">
              </ng-template>
            </ng-container>
          </td>
        </ng-container>

        <ng-container *ngSwitchDefault>
          <td *matCellDef="let element"
            mat-cell
            [style.width]="column.width">
            <ng-container *ngIf="element.id; else ghostTemplate">
              {{ element[column.def] }}
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #ghostTemplate>
      <div class="ghost"></div>
    </ng-template>

    <tr *matHeaderRowDef="displayedColumns; sticky: true"
      mat-header-row>
    </tr>
    <tr *matRowDef="let row; columns: displayedColumns;"
      mat-row
      [class.selectable]="!selectionDisabled && allowRowSelection"
      [class.selected]="selection?.isSelected(row.id)"
      (click)="!selectionDisabled && onRowSelect(row)">
    </tr>
  </table>
</ng-container>

<ng-template #emptyTemplate>
  <div class="empty-template"
    data-cy="empty-template"
    [class.no-border]="noBorder">
    <img *ngIf="emptyTableImage"
      [src]="emptyTableImage"
      alt="">

    <span *ngIf="emptyTableTitle"
      class="empty-template-title font-xxl-bold">
      {{ emptyTableTitle }}
    </span>

    <span class="message">
      {{ emptyTableMessage }}
    </span>

    <ng-content select="[emptyCustomHtml]"></ng-content>
  </div>
</ng-template>
