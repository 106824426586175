import { PathwayPlan } from '@accredible-frontend-v2/models';
import { CollectionsPlan, EmailCampaignsPlan } from './db-issuer.model';

export interface DashboardFeatures {
  analytics_engagement: boolean;
  analytics_marketing: boolean;
  audit_log: boolean;
  branding_package: boolean;
  collections: boolean;
  pathways: boolean;
  pathways_plan: PathwayPlan | null;
  collections_plan: CollectionsPlan | null;
  custom_marketing_message: boolean;
  digital_wallet_passes: boolean;
  directory: boolean;
  exp_analytics_revised_credential_engagement: boolean;
  spotlight_directory: boolean;
  private_spotlight_directory: boolean;
  is_customer: boolean;
  job_market_insights: boolean;
  manage_departments: boolean;
  premium_integrations: boolean;
  premium_whitelabel: boolean;
  recipient_ux_settings: boolean;
  stacked_credentials: boolean;
  transcript_letter: boolean;
  mfa: boolean;
  campaigns_plan: EmailCampaignsPlan | null;
  campaign_email_analytics: boolean;
  ace_endorsements: boolean;
}
