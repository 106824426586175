import { EmbeddingRouterLinkDirectiveModule } from '@accredible-frontend-v2/embedding';
import { AccredibleIconComponentModule } from '@accredible-frontend-v2/new-components/icon';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccredibleBreadCrumb } from './models/breadcrumbs.model';

@Component({
  standalone: true,
  selector: 'accredible-breadcrumbs',
  imports: [
    CommonModule,
    RouterModule,
    EmbeddingRouterLinkDirectiveModule,
    AccredibleIconComponentModule,
  ],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleBreadcrumbsComponent {
  breadcrumbs = input<AccredibleBreadCrumb[]>([]);
}

// TODO(Fred): We could try to make breadcrumbs work by default only with the URL, but we would need a solution for translations

// const getRouterLink = (
//   segment: string,
//   previousRouterLink: string | string[],
// ): string | string[] => {
//   if (previousRouterLink instanceof Array) {
//     previousRouterLink.push(segment);
//   } else {
//     previousRouterLink += `/${segment}`;
//   }
//
//   return previousRouterLink;
// };

// ngOnInit(): void {
//   this.crumbs = !!this.breadcrumbs?.length ? this.breadcrumbs : this._getBreadCrumbsFromUrl();
// }

// private _getBreadCrumbsFromUrl(): AccredibleBreadCrumb[] {
//   const urlSegments = this._router.url.split('/').filter((segment) => !!segment);
//   const crumbs: AccredibleBreadCrumb[] = [];
//
//   urlSegments.forEach((segment, index) => {
//     // skip last segment since it's the activated route
//     if (index === urlSegments.length - 1) return;
//
//     crumbs.push(<BreadCrumb>{
//       name: segment.replace('-', ' '),
//       routerLink: !index ? `/${segment}` : getRouterLink(segment, crumbs[index - 1].routerLink),
//     });
//   });
//
//   return crumbs;
// }
