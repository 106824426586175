import { AccredibleMenuComponent } from '@accredible-frontend-v2/new-components/menu';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'accredible-table-kebab-button',
  templateUrl: './table-kebab-button.component.html',
  styleUrls: [`./table-kebab-button.component.scss`],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccredibleTableKebabButtonComponent {
  @Input()
  menu: AccredibleMenuComponent;
  @Input()
  dataCy: string;
}
